import { useState, useEffect, FormEventHandler } from 'react';
import { Dialog } from '@components/shared/Dialog';
import { FiInfo } from 'react-icons/fi';
import { IndustryClassification, CSAM_INCIDENT_TYPE } from '../../../constants';
import { Loader } from '@components/shared/Loader';
import { kebabCaseToWords, getFullNameAsArray } from '@utils/string';
import { CsamReportType } from '@typeDef/Review';
import { PlatformTime } from '@components/shared/PlatformTime';
import { Button } from '@components/shared/Buttons';
import { useUser } from '@context/UserProvider';
import { Content, MetadataElement } from '@api/types';
import { Checkbox } from '@components/shared/Checkbox';

type CsamFormProps = {
  showCsamModal: boolean;
  hideCsamModal: () => void;
  actionSelected: any;
  onConfirmCsamModalClicked: FormEventHandler<HTMLFormElement>;
  csamForm: any;
  setCsamForm: (val: CsamReportType | undefined) => void;
  content?: Content;
  isUpdatingCase: boolean;
};

const CSAM_INDUSTRY_CLASSIFICATION = ['A1', 'A2', 'B1', 'B2'];
const CsamReport = ({
  showCsamModal,
  hideCsamModal,
  actionSelected,
  onConfirmCsamModalClicked,
  csamForm,
  setCsamForm,
  content,
  isUpdatingCase
}: CsamFormProps) => {
  const { platform, user } = useUser();
  const [showIndustryClassificationModal, setShowIndustryClassificationModal] =
    useState<boolean>(false);
  const [isCsamFormValid, setIsCsamFormValid] = useState<boolean>(false);

  function setCsamReporter(email: string) {
    const fullName = getFullNameAsArray(email);
    setCsamForm({
      ...csamForm,
      firstName: fullName?.length >= 1 ? fullName[0][0] : '',
      lastName: fullName?.length >= 2 ? fullName[1][0] : '',
      email
    });
  }

  useEffect(() => {
    setIsCsamFormValid(
      !!(
        csamForm?.incidentType &&
        (csamForm?.shouldEscalate ? csamForm?.escalationReason : true)
      )
    );
  }, [csamForm]);

  useEffect(() => {
    setCsamForm(undefined);
    if (!user) return;
    setCsamReporter(user?.email);
  }, [showCsamModal, user]);

  const setCsamFormField = (key: string, value: string | boolean) => {
    setCsamForm({
      ...csamForm,
      [key]: value
    });
  };

  return (
    <>
      <Dialog
        show={showCsamModal}
        close={hideCsamModal}
        dialogStyle="w-1/3 bg-custom-bg"
      >
        <div className="text-xl font-semibold mb-3 pb-2 border-b-2 border-lightgray">
          Report CSAM
        </div>
        <div className="border-b-2 border-lightgray pb-2">
          {actionSelected?.name && (
            <p>
              You are about to apply the{' '}
              <b>{kebabCaseToWords(actionSelected.name)}</b> enforcement action.
            </p>
          )}
          <p>Are you sure you have finished reviewing this piece of content?</p>
          <hr className="my-2" />
          <h4>CSAM Reporting</h4>
          <p>
            Items that breach the policy relating to child exploitation can
            additionally be reported directly to Cybertip hotline using the
            below form.
          </p>
          <hr className="my-2" />

          <form onSubmit={onConfirmCsamModalClicked}>
            <h4 className="mt-3">Reporter</h4>
            <div>
              <h4 className="my-1">First Name</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value={csamForm?.firstName}
                readOnly
              />
            </div>

            <div>
              <h4 className="my-1">Last Name</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value={csamForm?.lastName}
                readOnly
              />
            </div>

            <div>
              <h4 className="my-1">Email</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value={csamForm?.email}
                readOnly
              />
            </div>
            <div>
              <h4 className="my-1">Contact Person</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value={platform?.csamReportContactPerson}
                readOnly
              />
            </div>
            <div>
              <h4 className="my-1">Legal URL</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value={platform?.csamReportLegalUrl}
                readOnly
              />
            </div>

            <h4 className="mt-3">Incident Summary</h4>
            <div>
              <h4 className="my-1">Incident Type</h4>
              <select
                className="roundedContainer w-full"
                value={csamForm?.incidentType}
                onChange={(e) =>
                  setCsamFormField('incidentType', e.target.value)
                }
                required
                defaultValue={''}
              >
                <option disabled value={''}></option>
                {Object.keys(CSAM_INCIDENT_TYPE).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              {csamForm?.incidentType && (
                <div className="textMuted mt-1">
                  {CSAM_INCIDENT_TYPE[csamForm.incidentType]}
                </div>
              )}
            </div>

            <Checkbox
              title="Escalate To High Priority"
              value={csamForm?.shouldEscalate}
              checked={!!csamForm?.shouldEscalate}
              onChange={(e) =>
                setCsamFormField('shouldEscalate', e.target.checked)
              }
            />

            {csamForm?.shouldEscalate && (
              <div>
                <h4 className="my-1">Reason for escalation</h4>
                <input
                  type="text"
                  className="roundedContainer w-full"
                  value={csamForm?.escalationReason || ''}
                  onChange={(e) =>
                    setCsamFormField('escalationReason', e.target.value)
                  }
                  required
                />
              </div>
            )}

            <div className="my-2">
              <h4 className="my-1">Incident Date/Time</h4>
              <div className="roundedContainer w-full">
                <PlatformTime dt={content?.ts} />
              </div>
            </div>

            <div>
              <h4 className="my-1">Incident Date/Time Description</h4>
              <input
                type="text"
                className="roundedContainer w-full"
                value="Date and time of video uploaded"
                readOnly
              />
            </div>

            <h4 className="mt-3">Web Page Incident</h4>
            <div>
              <h4 className="my-1">URL</h4>
              <input
                type="text"
                className="roundedContainer w-full"
                value={content?.src}
                readOnly
              />
            </div>

            <h4 className="mt-3">File Details</h4>
            <div>
              <h4 className="my-1">
                Industry Classification
                <FiInfo
                  className="text-info ml-1"
                  onClick={() => {
                    setShowIndustryClassificationModal(true);
                  }}
                  style={{ fontSize: '18px', marginTop: '-3px' }}
                />
              </h4>
              <select
                className="roundedContainer w-full mb-1"
                value={csamForm?.industryClassification}
                onChange={(e) =>
                  setCsamFormField('industryClassification', e.target.value)
                }
                defaultValue={''}
              >
                <option disabled value={''}></option>
                {CSAM_INDUSTRY_CLASSIFICATION.map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <input type="checkbox" checked disabled />
              <span> File Viewed by ESP</span>
            </div>

            <div>
              <input type="checkbox" disabled />
              <span> Publicly Available</span>
            </div>

            <div className="mt-1">
              <h4>Location of File</h4>
              <input
                type="text"
                className="roundedContainer w-full"
                value={content?.src}
                readOnly
              />
            </div>

            <h4 className="mt-3">IP Capture</h4>
            <div>
              <h4 className="my-1">IP Address</h4>
              <input
                type="text"
                className="roundedContainer w-full"
                value={
                  content?.metadata?.find(
                    (e: MetadataElement) => e.id.toUpperCase() === 'IP'
                  )?.value
                }
                readOnly
              />
            </div>

            <div className="my-2">
              <h4>Event Name</h4>
              <input
                className="roundedContainer w-full"
                type="text"
                value="Upload"
                readOnly
              />
            </div>

            <div>
              <h4>Timestamp</h4>
              <div className="roundedContainer w-full">
                <PlatformTime dt={content?.ts} />
              </div>
            </div>
          </form>
        </div>
        <div className="flex gap-2 justify-end mt-3">
          {!isUpdatingCase ? (
            <>
              <Button
                type="primary"
                onClick={hideCsamModal}
                hiddenTitle="Close CSAM"
              >
                Close
              </Button>
              <Button
                type="primary"
                style="bg-error"
                onClick={onConfirmCsamModalClicked as any}
                disabled={!isCsamFormValid}
                title="Report CSAM"
              />
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Dialog>

      <Dialog
        dialogStyle="w-1/3 bg-custom-bg"
        show={showIndustryClassificationModal}
        close={() => {
          setShowIndustryClassificationModal(false);
        }}
      >
        <div className="text-xl font-semibold mb-3 pb-2 border-b-2 border-lightgray">
          {IndustryClassification.title}
        </div>
        <div className="border-b-2 border-lightgray pb-2">
          <p>{IndustryClassification.description}</p>

          <table className="mt-3" style={{ width: '100%' }}>
            <thead>
              <tr>
                {IndustryClassification.tableA.header.map((x) => (
                  <th className="csamthGrid" key={x}>
                    {x}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {IndustryClassification.tableA.body.rowA.map((x) => (
                  <td className="csamtdGrid" key={x}>
                    {x}
                  </td>
                ))}
              </tr>
              <tr>
                {IndustryClassification.tableA.body.rowB.map((x) => (
                  <td className="csamtdGrid" key={x}>
                    {x}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          <table className="mt-3">
            <thead>
              <tr>
                {IndustryClassification.tableB.header.map((x) => (
                  <th className="csamthGrid" key={x}>
                    {x}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {IndustryClassification.tableB.body.rowA.map((x) => (
                  <td className="csamtdGrid" key={x}>
                    {x}
                  </td>
                ))}
              </tr>
              <tr>
                {IndustryClassification.tableB.body.rowB.map((x) => (
                  <td className="csamtdGrid" key={x}>
                    {x}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
};

export { CsamReport };
