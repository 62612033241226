import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FiCopy } from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';

const headers = {
  Accept: 'application/json',
  Authorization: 'Bearer ${CHECKSTEP_API_KEY}',
  'Content-Type': 'application/json'
} as object;

const CurlPreview = ({ title, showDocs = false, payload }: any) => {
  if (!payload) return null;

  const snippet = generateCurl(payload || {});

  const copyCUrl = () => navigator.clipboard.writeText(snippet);

  return (
    <div className="mb-3">
      <div className="flex justify-between items-center my-3">
        <h4>{title}</h4>
        <div className="flex gap-2">
          <Button
            type="secondarySmall gap-2"
            onClick={copyCUrl}
            hiddenTitle="Copy CURL"
          >
            Copy <FiCopy />
          </Button>
          {showDocs && (
            <Button onClick={() => null} type="secondarySmall">
              <a
                target="_blank"
                href="https://docs.checkstep.com/#sending-data-to-checkstep"
                rel="noreferrer"
              >
                Docs
              </a>
            </Button>
          )}
        </div>
      </div>
      <SyntaxHighlighter
        data-testid="API snippet"
        language="bash"
        style={a11yDark}
      >
        {snippet}
      </SyntaxHighlighter>
    </div>
  );
};

const renderDataRow = (data: any, space: any = 2): string =>
  Object.keys(data)
    .map((key: string) => {
      if (key === 'fields') {
        const fields = JSON.parse(data[key]);
        return `\t\t"fields": [${fields.map(
          (x: any) => `{\n${renderDataRow(x, 3)} ${'\n\t\t'}}`
        )}]`;
      }
      if (typeof data[key] === 'string') {
        return `\t${Array.from(new Array(space)).join('\t')}"${key}": "${
          data[key]
        }"`;
      }
      if (Array.isArray(data[key])) {
        return `\t${Array.from(new Array(space)).join('\t')}"${key}": [${data[
          key
        ]
          .map((x: any) => `"${x}"`)
          .join(',')}]`;
      }
      if (typeof data[key] === 'object') {
        return `\t\t"${key}": {\n${renderDataRow(data[key], 3)} ${'\n\t\t'}}`;
      }
      return '';
    })
    .join(',\n');

const generateCurl = (payload: any) => `
curl -X "POST" \\
\t "${import.meta.env.VITE_API_BASE_URI}/api/v2/content" \\
${Object.entries(headers)
  ?.map(([key, val]: [string, string]) => `\t-H "${key}: ${val}" \\`)
  .join('\n')}
\t-d '{
  ${renderDataRow(payload)}
\t}'
`;

export default CurlPreview;
