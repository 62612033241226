import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { useUser } from '@context/UserProvider';
import { useForm } from 'react-hook-form';
import { Header } from '@components/shared/Header';
import '@components/style.css';
import { Moderator } from '@api/types';

type formState = {
  oldPassword: string;
  newPassword: string;
  newPasswordRe: string;
};

export default function Profile() {
  const { user } = useUser();
  const [submitResult, setSubmitResult] = useState<any>({});
  const [form] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRe: ''
  });
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: form });

  useEffect(() => {
    reset(form);
  }, []);

  const passwordStrengthRegex =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const handlePassword = (data: formState) => {
    const { oldPassword, newPassword } = data;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword).then(() => {
          setSubmitResult({
            submit: 'Password successfully changed.'
          });
          reset(form);
        });
      })
      .catch((err) => {
        if (err.message === 'Incorrect username or password.') {
          setSubmitResult({
            oldPassword: 'Incorrect old password'
          });
        } else {
          setSubmitResult({
            submitError: err.response
          });
        }
      });
  };

  function renderAttributes(user: Moderator) {
    return (
      <div>
        <div className="my-1 font-bold">Email</div>
        <input
          className="roundedContainer w-full focus:outline-none"
          type="text"
          value={user.email}
          readOnly
        />
      </div>
    );
  }

  function renderProfile() {
    return (
      <>
        {user && (
          <div className="flex gap-3 flex-col mlg:flex-row">
            <div className="min-w-[49%]">
              <h4 className="my-4">General</h4>
              <form className="flex flex-col gap-4">
                <div>
                  <div className="my-1 font-bold">Role</div>
                  <input
                    className="roundedContainer w-full focus:outline-none"
                    type="text"
                    value={user.role}
                    readOnly
                  />
                </div>
                {renderAttributes(user)}
              </form>
            </div>

            <div className="min-w-[49%]">
              <h4 className="my-4">Change password</h4>
              <div>
                <form
                  onSubmit={handleSubmit(handlePassword)}
                  className="flex flex-col gap-4"
                >
                  <div>
                    <div className="my-1 font-bold">Old Password</div>
                    <input
                      type="password"
                      className="roundedContainer w-full focus:outline-none"
                      {...register('oldPassword', {
                        required: 'This is a required'
                      })}
                    />
                    {errors.oldPassword && (
                      <p className="text-red">{errors.oldPassword.message}</p>
                    )}
                    {submitResult.oldPassword && (
                      <p className="text-red">{submitResult.oldPassword}</p>
                    )}
                  </div>
                  <div>
                    <div className="my-1 font-bold">New Password</div>
                    <input
                      type="password"
                      className="roundedContainer w-full focus:outline-none"
                      {...register('newPassword', {
                        required: 'This is required',
                        validate: (value) => {
                          return passwordStrengthRegex.test(value) &&
                            value.length >= 12
                            ? true
                            : 'Minimum 12 characters, lowercase letters, uppercase letters, numbers and special character.';
                        }
                      })}
                    />
                    {errors.newPassword && (
                      <p className="text-red">{errors.newPassword.message}</p>
                    )}
                  </div>
                  <div>
                    <div className="my-1 font-bold">New Password Again</div>
                    <input
                      type="password"
                      className="roundedContainer w-full focus:outline-none"
                      {...register('newPasswordRe', {
                        required: 'This is required',
                        validate: {
                          matchesPreviousPassword: (value) => {
                            const { newPassword } = getValues();
                            return newPassword === value
                              ? true
                              : 'Passwords should match';
                          }
                        }
                      })}
                    />
                    {errors.newPasswordRe && (
                      <p className="text-red">{errors.newPasswordRe.message}</p>
                    )}
                  </div>

                  <div className="flex justify-end">
                    <button className="primary mt-4 font-bold py-2">
                      Save
                    </button>
                  </div>

                  {submitResult.submit && (
                    <p className="text-red mt-2">{submitResult.submitError}</p>
                  )}

                  {submitResult.submit && (
                    <p className="mt-2 text-success">{submitResult.submit}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Profile Settings</h2>
        </div>
      </Header>
      <div className="px-8 pb-4">{user ? renderProfile() : 'Not Loaded'}</div>
    </>
  );
}
