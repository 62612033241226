import { useState } from 'react';
import { OrganizationTemplate, CreateOrganizationRequest } from '@api/types';
import { useApiService } from '@api/services';
import { SubmitButtons } from '@components/shared/Buttons';
import { useNavigate } from 'react-router-dom';

const defaultValues = {
  organizationName: '',
  platformName: ''
};
const CreateOrg = () => {
  const navigate = useNavigate();
  const { postNewOrganization } = useApiService();
  const [payload, setPayload] =
    useState<CreateOrganizationRequest>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const postNewOrg = () => {
    if (!payload) return;
    setIsLoading(true);
    postNewOrganization.mutate(payload, {
      onSuccess: async () => navigate(0),
      onError: async (err) => console.log('err', err),
      onSettled: () => {
        setIsLoading(false);
        setPayload(defaultValues);
      }
    });
  };

  return (
    <div className="bg-gradient-to-r from-[#14152F] to-[#4B4D8C] flex flex-col lg:flex-row h-screen">
      <div className="p-8 md:px-40 lg:p-12 flex flex-col lg:flex-row lg:m-auto gap-4 lg:gap-44 max-w-[1580px]">
        <div className="text-white flex items-center gap-4 lg:gap-8">
          <img
            src="/assets/images/logo.svg"
            alt="Checkstep logo"
            className="lg:w-52"
          />
          <div className="text-[50px] lg:text-[80px] font-bold text-[#ffff]">
            checkstep
          </div>
        </div>

        <div className="bg-[#ffff] p-8 rounded-md flex gap-6 flex-col lg:w-[460px]">
          <h2>Get started with your checkstep account</h2>
          <div className="flex flex-col gap-2">
            <div className="font-bold">Organisation name</div>
            <input
              type="text"
              placeholder="Your company name"
              className="border-[1px] border-[#E0E1FF] rounded-md p-2"
              onChange={(e) =>
                setPayload({ ...payload, organizationName: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-bold">Platform name</div>
            <input
              type="text"
              placeholder="Your platform name"
              className="border-[1px] border-[#E0E1FF] rounded-md p-2"
              onChange={(e) =>
                setPayload({ ...payload, platformName: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-bold">Choose a template</div>
            <div className="flex justify-between gap-4">
              <button
                className={`${payload.template === OrganizationTemplate.GENERIC && 'bg-[#E0E1FF] border-[#6366F1]'} text-left border-[1px] border-[#E0E1FF] rounded-md p-4 flex flex-col gap-2 w-full"`}
                onClick={() =>
                  setPayload({
                    ...payload,
                    template: OrganizationTemplate.GENERIC
                  })
                }
              >
                <div className="font-bold text-lg">Generic</div>
                <div className="text-sm text-light">
                  A generic test account for moderation use cases
                </div>
              </button>
              <button
                className={`${payload.template === OrganizationTemplate.MARKETPLACE && 'bg-[#E0E1FF] border-[#6366F1]'} text-left border-[1px] border-[#E0E1FF] rounded-md p-4 flex flex-col gap-2 w-full"`}
                onClick={() =>
                  setPayload({
                    ...payload,
                    template: OrganizationTemplate.MARKETPLACE
                  })
                }
              >
                <div className="font-bold text-lg">Marketplace</div>
                <div className="text-sm text-light">
                  A test account for production listing use cases
                </div>
              </button>
            </div>
          </div>
          <SubmitButtons
            submitStyle="p-2 justify-center bg-[#6366F1] font-bold text-[#ffff] rounded-md disabled:bg-[#808080] disabled:text-[#ffff] hover:text-[#ffff] hover:bg-[#C7C9FE]"
            onSubmit={postNewOrg}
            submitLabel="Confirm sign up"
            isLoading={isLoading}
            disabled={
              !payload.template ||
              !payload.organizationName ||
              !payload.platformName
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateOrg;
