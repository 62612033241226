import { useState, ChangeEvent } from 'react';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';
import { Input } from '@components/shared/Input';
import { Textarea } from '@components/shared/Textarea';
import { SubmitButtons } from '@components/shared/Buttons';
import { PolicyCreate, Policy } from '@api/types';

const AddNewPolicy = ({
  onClose,
  policyList
}: {
  onClose: () => void;
  policyList: Policy[];
}) => {
  const queryClient = useQueryClient();
  const { postNewPolicy } = useApiService();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [nameErrorMsg, setNameErrorMsg] = useState<string>();
  const [policy, setPolicy] = useState<PolicyCreate>({
    name: '',
    code: '',
    description: ''
  });

  const isExistingCode = (code: string) =>
    policyList.find((p: Policy) => p.code.toLowerCase() === code.toLowerCase());

  const isSpecialChars = (code: string) => !/^[A-Za-z0-9_]*$/.test(code);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (policy && !isExistingCode(policy.code)) {
      postNewPolicy.mutate(
        { policy },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({
              queryKey: ['policies']
            });
            onClose();
          },
          onError: () => {
            setErrorMsg('Please try later');
          }
        }
      );
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const newPolicy = { ...policy, [id]: value };

    isExistingCode(newPolicy.code) &&
      setNameErrorMsg('Already existing policy code');

    isSpecialChars(newPolicy.code) &&
      setNameErrorMsg('Special chars are not allowed');

    if (!isExistingCode(newPolicy.code) && !isSpecialChars(newPolicy.code)) {
      setNameErrorMsg('');
    }

    setPolicy(newPolicy);
    setErrorMsg('');
  };

  return (
    <div>
      <h4>Add new policy</h4>
      <form className="flex gap-6 flex-col mt-6">
        <Input
          htmlFor="name"
          label="Name"
          type="text"
          required
          placeholder="Add the policy name"
          labelStyle="text-md font-bold"
          onChange={onChange}
        />
        <Input
          htmlFor="code"
          label="Policy code"
          type="text"
          required
          placeholder="Add the policy code"
          labelStyle="text-md font-bold"
          onChange={onChange}
          maxLength={12}
          errorMsg={nameErrorMsg}
        />
        <Textarea
          htmlFor="description"
          label="Description"
          required
          placeholder="Add the policy description"
          labelStyle="text-md font-bold"
          onChange={onChange}
        />
        <Textarea
          htmlFor="guidelines"
          label="Guidelines"
          placeholder="Add the policy guidelines"
          labelStyle="text-md font-bold"
          onChange={onChange}
        />
        <div className="flex gap-2 justify-end mt-4">
          {errorMsg ? (
            <div className="text-error text-lg">{errorMsg}</div>
          ) : (
            <SubmitButtons
              onClose={onClose}
              onSubmit={onSubmit}
              submitLabel="Confirm"
              isLoading={postNewPolicy?.isPending}
              submitStyle="h-12 ml-2"
              disabled={
                !policy.name ||
                !policy.code ||
                !policy.description ||
                !!isExistingCode(policy.code) ||
                !!isSpecialChars(policy.code)
              }
            />
          )}
        </div>
      </form>
    </div>
  );
};

export { AddNewPolicy };
