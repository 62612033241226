import { useRef, useState } from 'react';
import { PlatformTime } from '@components/shared/PlatformTime';
import { IncidentStatus } from '@components/shared/IncidentStatus';
import { Dialog } from '@components/shared/Dialog';
import { ViewEvent } from '@components/shared/CaseWrappers/ViewEvent';
import { ContentCaseShort, ContentCase } from '@api/types';
import { BulkReviewCase } from '@typeDef/Review';
import { Button } from '@components/shared/Buttons';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { FaComments, FaEye } from 'react-icons/fa6';
import { TbVersionsFilled } from 'react-icons/tb';
import { FaCalendarAlt, FaUserCircle } from 'react-icons/fa';
import { InteractiveLabel } from '../InteractiveLabel';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { PreviewContent } from './PreviewContent';

const ListCard = ({
  contentCase,
  selectedCS,
  setSelectedCS,
  isNotQA = true,
  queueId,
  showTranslate = true
}: {
  contentCase: ContentCaseShort;
  selectedCS?: Map<string, BulkReviewCase | ContentCase>;
  setSelectedCS?: (selectedCS: Map<string, any>) => void;
  isNotQA?: boolean;
  queueId?: string;
  showTranslate?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const textRef = useRef<any>(null);
  const content = contentCase?.contents?.at(-1);
  const incident = contentCase?.incidents?.at(-1);
  const selected = selectedCS?.has(contentCase?.cid);
  const navigate = useNavigate();
  const { platform } = useUser();

  const onClick = () => {
    if (!isNotQA) return;
    selected
      ? selectedCS?.delete(contentCase?.cid)
      : selectedCS?.set(contentCase?.cid, contentCase);
    setSelectedCS?.(new Map(selectedCS));
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClick();
        }
      }}
      className={`${expanded ? 'min-h-[70px] max-h-[100vh]' : 'h-[70px]'} border-border border px-6 py-2 bg-custom-bg hover:shadow-md hover:bg-highlight ${selected && 'outline outline-cta bg-highlight outline-offset-[-1px]'} ${isNotQA && 'cursor-pointer'} first:rounded-t-md last:rounded-b-md block`}
      onClick={onClick}
    >
      <div className="flex flex-row items-start mt-2.5 justify-between w-full h-full">
        <div className="flex flex-row gap-2 min-w-[50%] max-w-[50%]">
          {!Object.keys(contentCase).includes('_primary_term') && (
            <div className="mr-2">
              <Tooltip
                text={
                  incident?.status
                    ? `Incident ${incident?.status}`
                    : 'No Incident'
                }
              >
                <IncidentStatus incident={incident} size="small" />
              </Tooltip>
            </div>
          )}
          <PreviewContent
            textRef={textRef}
            expanded={expanded}
            setExpanded={setExpanded}
            content={content}
            showTranslate={showTranslate}
          />
        </div>

        <div className="ml-2 items-center mt-1 max-w-[48%] grid grid-cols-8">
          <div className="col-span-2 flex justify-center xl:justify-start">
            <InteractiveLabel
              onClick={() => {
                content?.parent?.cid &&
                  navigate(
                    `/explore?parentId=${encodeURIComponent(content?.parent?.cid || '')}&parentComplexType=${encodeURIComponent(content?.parent?.ct || '')}`
                  );
              }}
              title={content?.parent?.cid || '-'}
              icon={<FaComments size={20} className="text-cta" />}
              tooltip="Click to view parent case"
              style="w-20 hidden lg:block mt-1"
            />
          </div>

          <div className="flex flex-row gap-1 items-center col-span-2">
            <FaCalendarAlt size={16} className="flex-shrink-0" />
            <PlatformTime
              dt={contentCase.ts}
              style="w-[140px] text-sm lg:text-md line-clamp-1"
            />
          </div>
          <div className="flex gap-1 flex-row col-span-1 xl:col-span-2 justify-end xl:justify-start">
            <InteractiveLabel
              title={contentCase.cid}
              icon={<TbVersionsFilled size={20} />}
              style="w-[150px] hidden xl:block"
            />
            <InteractiveLabel
              title={content?.author || '-'}
              onClick={() =>
                navigate(
                  `/explore?author=${encodeURIComponent(content?.author || '')}`
                )
              }
              icon={<FaUserCircle size={18} />}
              style="w-[150px] hidden xl:block"
            />
          </div>
          <div className="flex gap-2 items-start ml-2 col-span-3 xl:col-span-2 justify-end">
            <Button
              type="icon h-8 w-8"
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
              }}
              hiddenTitle="View More"
            >
              <FaEye className="min-w-[10px]" data-testid="more" />
            </Button>
            <Button
              type="primarySmall justify-center"
              onClick={() => {
                navigate(
                  `/queues/${queueId ?? 'noqueue'}/review/event/${encodeURIComponent(contentCase.cid)}/${encodeURIComponent(contentCase.ct)}?pid=${platform?.id}`
                );
              }}
              hiddenTitle="Moderate case"
            >
              Moderate
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        show={show}
        close={() => setShow(false)}
        dialogStyle="w-2/3 bg-custom-bg relative cursor-default"
      >
        <div className="px-6 pb-2">
          <ViewEvent contentCase={contentCase} setShow={setShow} />
        </div>
      </Dialog>
    </div>
  );
};

export { ListCard };
