import { HistoryTabs } from '@components/Review/Content/HistoryTabs';
import { FiCopy, FiLink } from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { ContentFields } from '@components/shared/Content/Fields';
import { useState } from 'react';
import { useApiService } from '@api/services';
import { Siblings } from '@components/Review/Content/Siblings';
import { Accordion } from '@components/shared/Accordion';

const ContentInReview = ({
  contentCase,
  selectedProperty,
  setSelectedProperty,
  showTimeline,
  setShowTimeline,
  qa
}: any) => {
  const { useSiblingsBefore, useSiblingsAfter } = useApiService();
  const [before, setBefore] = useState<number>(1);
  const [after, setAfter] = useState<number>(1);

  const contentFields = contentCase?.contents?.at(-1)?.fields;

  const { data: siblingBefore = [] } = useSiblingsBefore({
    complex_type: contentCase.ct,
    content_id: contentCase.cid,
    before
  });

  const { data: siblingAfter = [] } = useSiblingsAfter({
    complex_type: contentCase.ct,
    content_id: contentCase.cid,
    after
  });

  const hasSiblings = siblingBefore?.length || siblingAfter?.length;

  return (
    <HotkeysProvider>
      <div className="mb-4 relative">
        <div className="flex justify-between mb-1.5">
          <h5>In Review</h5>
          <div className="flex gap-2 h-9">
            <Button
              type="icon"
              style="h-7 w-7"
              tooltip="Copy ContentId"
              tooltipStyle="w-20"
              onClick={() => navigator.clipboard.writeText(contentCase.cid)}
              hiddenTitle="Copy ContentId"
            >
              <FiCopy size="16" />
            </Button>
            <Button
              type="icon"
              style="h-7 w-7"
              tooltip="View Live"
              onClick={() =>
                window.open(
                  contentCase.contents?.at(-1)?.src ||
                    contentCase.communityReports?.at(-1)?.src
                )
              }
              hiddenTitle="View Live"
            >
              <FiLink size="16" />
            </Button>
            {!qa && (
              <Button
                type="secondarySmall"
                style="h-7"
                tooltip="Open event timeline"
                tooltipStyle="w-24"
                title="Timeline"
                onClick={() => setShowTimeline(!showTimeline)}
              />
            )}
          </div>
        </div>
        <div
          className={`max-h-[calc(100vh/1.3)] overflow-auto flex flex-col items-center ${hasSiblings ? 'border border-border rounded-md' : 'pb-6'}`}
        >
          {siblingBefore?.length >= before && (
            <div className="border-b border-border w-full flex justify-center">
              <Button
                onClick={() => setBefore((prev) => prev + 2)}
                type="secondarySmall"
                style="my-1 h-10"
                hiddenTitle="Review context load prev"
              >
                Load previous
              </Button>
            </div>
          )}

          <div
            className={`flex flex-col items-center px-3 w-full ${hasSiblings ? 'overflow-auto max-h-[60vh]' : ''}`}
          >
            {siblingBefore?.map((x: any) => (
              <Siblings contentCase={x} key={x?.cid} />
            ))}

            <div className={`w-full mt-1 ${hasSiblings ? 'px-3' : ''}`}>
              <ContentFields
                fields={contentFields}
                selectedProperty={selectedProperty}
                setSelectedProperty={setSelectedProperty}
              />
            </div>
            {siblingAfter?.map((x: any) => (
              <Siblings contentCase={x} key={x?.cid} />
            ))}
          </div>

          {siblingAfter?.length >= after && (
            <div className="border-t border-border w-full flex justify-center">
              <Button
                onClick={() => setAfter((prev) => prev + 2)}
                type="secondarySmall"
                style="my-1 h-10"
                hiddenTitle="Review context load more"
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="2xl:hidden">
        <Accordion
          title="Activity"
          show={
            !!contentCase?.inquiries ||
            !!contentCase?.communityReports ||
            !!contentCase.appeals
          }
        >
          <HistoryTabs
            inquiries={contentCase?.inquiries}
            reports={contentCase?.communityReports}
            appeals={contentCase?.appeals}
          />
        </Accordion>
      </div>
      <div className="hidden 2xl:block -mt-6">
        <HistoryTabs
          inquiries={contentCase?.inquiries}
          reports={contentCase?.communityReports}
          appeals={contentCase?.appeals}
        />
      </div>
    </HotkeysProvider>
  );
};

export { ContentInReview };
