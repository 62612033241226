import { useEffect, useState } from 'react';
import './App.scss';
import './Spinner.css';
import { Auth } from '@aws-amplify/auth';
import { getLogoutURL } from './utils/user';
import Routes from './Routes';
import Layout from '@components/Layout';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { AuthProvider } from '@context/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserProvider, useUser } from '@context/UserProvider';
import { ContentProvider } from '@context/ContentProvider';
import { NotificationProvider } from '@context/NotificationProvider';
import { QueuesProvider } from '@context/QueuesProvider';
import { DateProvider } from '@context/DateProvider';
import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { AnalyticsProvider } from '@context/AnalyticsProvider';
import CreateOrg from '@components/Signup/CreateOrg';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const InitApp = () => {
  const { platformError, platform, isPlatformFetchEnabled, user, theme } =
    useUser();
  const navigate = useNavigate();

  if (
    !platform &&
    isPlatformFetchEnabled === 'idle' &&
    !user?.isRegistered &&
    user?.id
  ) {
    return <CreateOrg />;
  }
  if (!platform && !platformError) return null;

  if (platformError)
    return (
      <div className="flex items-center justify-center h-1/2">
        <div className="w-52">
          <img src="/assets/images/error.png" alt="error" width="200" />
          <h4 className="mb-2">Something went wrong</h4>
          <div>
            <button className="primary mt-2 mr-3 bg-gray">
              <a href="https://checkstep.atlassian.net/servicedesk/customer/portal/1">
                Open ticket
              </a>
            </button>
            <button onClick={() => navigate(0)} className="primary mt-2">
              Reload
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`flex h-screen ${theme} `}>
      <Layout />
      <div className="overflow-x-hidden w-full">
        <Routes />
      </div>
    </div>
  );
};

function App() {
  const [loaded, setLoaded] = useState(false);

  const redirectSignOut = () => {
    const config = Auth.configure();
    const clientId = config.userPoolWebClientId;
    const url = getLogoutURL(config.oauth, clientId);
    window.location.assign(url);
  };

  const checkAuth = async () => {
    const verifier = CognitoJwtVerifier.create({
      userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
      tokenUse: 'id',
      clientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
    });

    try {
      const session = await Auth.currentSession();
      const token = session?.getIdToken().getJwtToken();
      await verifier.verify(token);
      setLoaded(true);
    } catch {
      redirectSignOut();
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (!loaded) {
    return (
      <div style={{ backgroundColor: '#111529' }} className="loading-screen">
        <img
          className="loading-screen-img"
          width="150"
          height="150"
          src={`/assets/images/logo.svg`}
          alt="Loading"
        />
      </div>
    );
  }

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <UserProvider>
            <ContentProvider>
              <QueuesProvider>
                <NotificationProvider>
                  <DateProvider>
                    <AnalyticsProvider>
                      <InitApp />
                    </AnalyticsProvider>
                  </DateProvider>
                </NotificationProvider>
              </QueuesProvider>
            </ContentProvider>
          </UserProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
