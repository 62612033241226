import { useState, Fragment } from 'react';
import { Button } from '@components/shared/Buttons';
import { FaPencilAlt, FaInfoCircle } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { getPolicyByLabel } from '@components/shared/LabelsManagement/helper';
import { Policy, PolicyRule } from '@api/types';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';
import { PolicyChart } from '@components/Policy/PolicyChart';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { LOWER_THRESHOLD, UPPER_THRESHOLD } from '@src/constants';
import { ManageRule } from '@components/shared/LabelsManagement/ManageRule';
import { SelectPolicy } from '@components/shared/LabelsManagement/SelectPolicy';

const header = [
  'Policy',
  'Queue content threshold',
  'Automatic enforcement threshold',
  'Restricted to',
  ''
];

const LabelsManagement = ({ label }: { label?: string }) => {
  const { policies, platform } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { putPolicyRule } = useApiService();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [policy, setPolicy] = useState<Policy | undefined>(undefined);
  const [rule, setRule] = useState<any>(undefined);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isChartOpen, setIsChartOpen] = useState<boolean>(false);

  const onClose = () => {
    setShowAdd(false);
    setPolicy(undefined);
    setRule(undefined);
    setIsChartOpen(false);
  };

  const putRules = (rules: PolicyRule[], policy: Policy) => {
    putPolicyRule.mutate(
      { rules, code: policy.code || '' },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['policies'] });
          setIsChartOpen(false);
          setShowAdd(false);
        },
        onError: async () => {
          setErrorMsg('Failed to submit change, please try again later.');
        }
      }
    );
  };

  const onChange = (e: any, key: keyof PolicyRule) => {
    const value = typeof e === 'string' ? e : Number(e.target.value);
    const newRule = { ...rule, [key]: value };
    setRule(newRule);
  };

  const onDelete = (policy: Policy, rule: PolicyRule) => {
    if (!policy || !rule) return;
    const rules = policy.policy_rules.filter(
      (r: PolicyRule) => r.label !== rule.label
    );
    putRules(rules, policy);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!policy) return;
    const rules = JSON.parse(JSON.stringify(policy.policy_rules));

    const index = rules.findIndex((r: PolicyRule) => r.label === label);
    const normalisedRule = {
      ...rule,
      lower_threshold: rule.lower_threshold / 100,
      upper_threshold: rule.upper_threshold / 100
    };
    index !== -1 ? (rules[index] = normalisedRule) : rules.push(normalisedRule);

    putRules(rules, policy);
  };

  const onClick = () => {
    const dialog = document.getElementById('dialog');
    if (dialog) dialog.style.display = 'none';
    navigate(`/explore?pid=${platform?.id}&size=24&labels=${label}`);
  };

  const isSelectedLabel = (ruleArg: PolicyRule, policyCode: string) => {
    if (!rule) return;
    return (
      ruleArg.label === rule.label &&
      rule.content_type === ruleArg?.content_type &&
      rule.policyCode === policyCode
    );
  };

  const getTooltip = (x: string) =>
    x.includes('Queue') ? LOWER_THRESHOLD : UPPER_THRESHOLD;

  const getPercentages = (rule: PolicyRule, policyCode: string) => ({
    ...rule,
    lower_threshold: getPercentage('lower_threshold', rule),
    upper_threshold: getPercentage('upper_threshold', rule),
    policyCode
  });

  const getPercentage = (key: keyof PolicyRule, rule?: PolicyRule) => {
    if (!rule) return 0;
    const value = rule[key] as number;
    return parseFloat((value * 100).toFixed(2));
  };

  if (!label) return;
  const filteredPolicyByLabel = getPolicyByLabel(label, policies);

  return (
    <div className="flex gap-8 flex-col">
      <div className="w-full flex justify-between gap-4 items-center">
        <div className="text-lg capitalize overflow-hidden">{label}</div>
        <div className="flex gap-4 mr-8 min-w-fit">
          <Button
            title="View all contents"
            onClick={onClick}
            type="primarySmall"
          />
          <Button
            title="Add new rule"
            onClick={() => {
              setShowAdd(true);
              setPolicy(undefined);
              setRule({ label });
            }}
            type="primarySmall"
          />
        </div>
      </div>
      {!showAdd && (
        <div className="rounded-md border border-border">
          <table className="w-full border-collapse">
            <thead className="bg-custom-bg h-[72px] border-border border-b">
              <tr>
                {header.map((x: string) => (
                  <th
                    className="ps-0 font-bold first:w-1/4 ml-2 p-3 first:indent-5 first:rounded-tl-md last:rounded-tr-md"
                    key={x}
                  >
                    <div className="flex gap-1">
                      <span className="flex flex-wrap max-w-44 text-left">
                        {x}
                      </span>
                      <div>
                        {x.includes('threshold') && (
                          <Tooltip text={getTooltip(x)} textStyle="w-44">
                            <FaInfoCircle className="mb-1 w-4 h-4" />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="w-full">
              {filteredPolicyByLabel.map((policy: any) =>
                policy.labels.map((rule: any) => (
                  <Fragment key={rule.label}>
                    <tr
                      className="p-4"
                      key={`${rule.label}-${rule.content_type}`}
                    >
                      <td className="ps-0 font-bold first:indent-5 first:rounded-tl-md last:rounded-tr-md">
                        {policy.name}
                      </td>
                      <td>{getPercentage('lower_threshold', rule)}%</td>
                      <td>{getPercentage('upper_threshold', rule)}%</td>
                      <td>{rule.content_type}</td>
                      <td colSpan={header.length}>
                        <div className="flex gap-2 justify-end m-1 p-3">
                          <Button
                            type="icon"
                            hiddenTitle="showChart"
                            onClick={() => {
                              setIsChartOpen(!isChartOpen);
                              setPolicy(policy);
                              setRule(getPercentages(rule, policy?.code));
                            }}
                          >
                            {isChartOpen ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )}
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      {isChartOpen && isSelectedLabel(rule, policy?.code) && (
                        <td colSpan={header.length}>
                          <div className="flex gap-2 justify-end m-1 p-3">
                            <Button
                              hiddenTitle="editRule"
                              tooltip="Edit rule"
                              tooltipStyle="bottom min-h-[0px] max-h-[28px]"
                              type="icon"
                              style="w-7 h-7"
                              onClick={() => setShowAdd(true)}
                            >
                              <FaPencilAlt size="14" />
                            </Button>
                            <Button
                              type="icon"
                              style="text-error w-7 h-7  border-error hover:bg-error hover:text-white"
                              hiddenTitle="deleteRule"
                              tooltip="Delete rule"
                              onClick={() => onDelete(policy, rule)}
                            >
                              <MdDelete size="20" />
                            </Button>
                          </div>
                          <div
                            className="w-full relative"
                            data-testid="ruleChart"
                          >
                            <PolicyChart
                              label={rule?.label}
                              threshold={rule?.lower_threshold * 100}
                              upperThreshold={rule?.upper_threshold * 100}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      {errorMsg && (
        <div className="text-error flex justify-end">{errorMsg}</div>
      )}
      {showAdd && (
        <div className="px-4 max-h-[50%]">
          <div className="flex flex-col">
            <div className="flex flex-col max-h-96">
              <SelectPolicy policy={policy} setPolicy={setPolicy} />
              <ManageRule
                onSubmit={onSubmit}
                onChange={onChange}
                policy={policy}
                rule={rule}
                error={errorMsg}
                onClose={onClose}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { LabelsManagement };
