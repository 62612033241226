import { FormEvent, useState, useEffect } from 'react';
import { PolicyRuleType } from '@typeDef/Policy';
import { ManageRule } from '@components/shared/LabelsManagement/ManageRule';
import { PolicyRule, Policy } from '@api/types';

type Props = {
  onEdit: (rule: PolicyRuleType) => void;
  ruleSelected: PolicyRuleType | PolicyRule | null;
  onClose: () => void;
  policy: Policy;
};

const EditRuleForm = ({ ruleSelected, onEdit, onClose, policy }: Props) => {
  const [error, setError] = useState('');
  const [rule, setRule] = useState<any>(ruleSelected);

  useEffect(() => {
    setError('');
  }, [rule]);

  if (!ruleSelected) return null;

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (error) return;

    onEdit(rule);
  };

  const onChange = (e: any, key: keyof PolicyRule) => {
    const value = typeof e === 'string' ? e : Number(e.target.value);
    const newRule = { ...rule, [key]: value };
    setRule(newRule);
  };

  return (
    <ManageRule
      onSubmit={onSubmit}
      onChange={onChange}
      rule={rule}
      error={error}
      title="Edit rule"
      onClose={onClose}
      policy={policy}
    />
  );
};

export { EditRuleForm };
