import { useUser } from '@context/UserProvider';
import { DateTime } from 'luxon';

const PlatformTime = ({
  dt,
  style = 'text-xs'
}: {
  dt?: DateTime | string | Date;
  style?: string;
}) => {
  const { platform } = useUser();

  if (!dt) return null;

  if (typeof dt === 'string') {
    dt = DateTime.fromISO(dt);
    return (
      <div className={style}>
        {dt.setZone(platform?.timezone).toFormat('ff')}
      </div>
    );
  }
};

export { PlatformTime };
