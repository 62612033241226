import { useEffect, useState } from 'react';
import { TextContent } from '../Content/TextContent';
import { Content } from '@api/types';
import { ContentFields } from '../Content/Fields';
import { Button } from '@components/shared/Buttons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const PreviewContent = ({
  textRef,
  expanded,
  setExpanded,
  content,
  showTranslate
}: {
  textRef: any;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  content?: Content;
  showTranslate: boolean;
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const firstField = content?.fields?.find(
    (field) => field?.type === 'text'
  )?.src;

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;
      if (element) {
        const multiField = !!(
          content?.fields?.length && content?.fields?.length > 1
        );
        const truncated =
          element.scrollWidth > element.clientWidth ||
          !firstField ||
          multiField;
        setIsTruncated(truncated);
      }
    };

    checkTruncation();

    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [expanded]);

  return (
    <div className="flex flex-row flex-grow justify-between overflow-x-hidden">
      <div
        className={`${expanded ? 'overflow-y-auto max-h-[calc(100vh-50px)]' : 'max-h-[55px] truncate'} -mt-1`}
      >
        <div
          className="overflow-y-visible break-words cursor-default flex flex-col"
          ref={textRef}
        >
          {expanded ? (
            <ContentFields
              fields={content?.fields}
              showTranslate={showTranslate}
            />
          ) : (
            <div className="mt-2">
              <TextContent val={firstField ?? '[Expand to view content]'} />
            </div>
          )}
        </div>
      </div>
      <Button
        style={`icon p-2 w-7 h-7 mt-1 ml-2 ${expanded ? 'bg-cta text-white' : ''} ${!isTruncated && !expanded ? 'invisible' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        {expanded && <IoIosArrowUp size={18} />}
        {!expanded && <IoIosArrowDown size={18} />}
      </Button>
    </div>
  );
};
export { PreviewContent };
