import { useState } from 'react';
import csvDownload from 'json-to-csv-export';
import { Loader } from '@components/shared/Loader';
import { useApiService } from '@api/services';
import { getDailySummaryData } from '@components/Dashboard/helper';
import { Table } from '@components/shared/Table';
import { DateTime } from 'luxon';
import { Button } from '@components/shared/Buttons';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { Dropdown } from '@components/shared/Dropdown';
import { FaFileExport } from 'react-icons/fa6';

type DailySummaryProps = {
  startDate: DateTime;
  endDate: DateTime;
};

const DailySummary = ({ startDate, endDate }: DailySummaryProps) => {
  const [interval, setInterval] = useState<string>('day');
  const intervals = ['day', 'week', 'month'];
  const { useReportInTime } = useApiService();
  const [show, setShow] = useState<boolean>(true);

  const { data, isLoading } = useReportInTime({
    interval,
    startDate,
    endDate
  });

  if (isLoading) return <Loader />;
  if (!data?.length) return null;

  const { columns, tableData } = getDailySummaryData(data);

  const dataToConvert = {
    data: tableData.map((item: any) => Object.values(item)),
    filename: 'daily report',
    delimiter: ',',
    headers: columns
  };

  const exportCSV = () => csvDownload(dataToConvert);

  const normalizedSummary = tableData?.map((row: any) =>
    Object.entries(row).map(([header, val]: any) => ({
      header: (
        <Tooltip text={header} textStyle="min-h-[40px]">
          <div className="m-2 text-ellipsis overflow-hidden w-24 h-12">
            {header}
          </div>
        </Tooltip>
      ),
      val: typeof val === 'number' ? parseFloat(val.toFixed(2)) : val
    }))
  );

  return (
    <div className="border border-border my-4 w-full overflow-visible p-0 bg-custom-bg rounded-t-md">
      <div className="flex justify-between items-center p-3">
        <h4>Summary</h4>
        <div className="flex gap-2 relative">
          <Dropdown
            title={`Per ${interval}`}
            list={intervals}
            onAction={setInterval}
            mainClass="p-0 max-w-max bg-custom-bg"
            buttonStyle="rounded-sm border border-border p-2 hover:bg-transparent hover:border-border h-9 flex justify-center items-center"
            listStyle="absolute z-[100] border bg-custom-bg border-neutral-200 w-24 rounded-sm overflow-auto bottom-8"
            itemStyle="hover:bg-transparent hover:text-cta"
          />
          <Button
            type="icon"
            style="h-9 w-9"
            onClick={exportCSV}
            tooltip="Export Csv"
          >
            <FaFileExport size={17} />
          </Button>
          <Button
            type="secondarySmall"
            onClick={() => setShow(!show)}
            title={show ? 'Hide' : 'Show'}
          />
        </div>
      </div>
      {show && (
        <div
          style={{ maxHeight: '400px', overflowY: 'auto', padding: 0 }}
          className="border-y border-border"
        >
          <Table list={normalizedSummary} />
        </div>
      )}
    </div>
  );
};

export { DailySummary };
