import { useState, DragEvent } from 'react';
import { useUser } from '@context/UserProvider';
import { useApiService } from '@api/services';
import { Policy } from '@api/types';
import { useQueryClient } from '@tanstack/react-query';
import { FaGripVertical } from 'react-icons/fa6';
import { SubmitButtons } from '@components/shared/Buttons';

const PolicyPriority = ({ policyList, onClose }: any) => {
  const { user } = useUser();
  const { putPolicyRank } = useApiService();
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState<string>();

  const [policies, setPolicies] = useState<Policy[]>(policyList);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const onDragStart = (index: number) => setDraggedIndex(index);

  const onDragOver = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    setHoveredIndex(index);
  };

  const onDrop = () => {
    if (
      draggedIndex !== null &&
      hoveredIndex !== null &&
      draggedIndex !== hoveredIndex
    ) {
      const newPolicies = [...policies];
      const draggedPolicy = newPolicies[draggedIndex];

      newPolicies.splice(draggedIndex, 1);
      newPolicies.splice(hoveredIndex, 0, draggedPolicy);

      setPolicies(newPolicies);
    }
    setDraggedIndex(null);
    setHoveredIndex(null);
  };

  const onSubmit = (policies: Policy[]) => {
    if (policies) {
      putPolicyRank.mutate(
        {
          codes: policies.map((policy: Policy) => policy.code)
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({
              queryKey: ['policies']
            });
            onClose();
          },
          onError: () => {
            setErrorMsg('Please try later');
          }
        }
      );
    }
  };

  return (
    <div>
      <h4 className="mb-4">Rearrange policy order</h4>
      <div className="flex flex-col">
        {policies?.map((policy: Policy, index: number) => (
          <div
            className={`flex w-full gap-8 items-center border-b border-border py-4 hover:bg-highlight ${user?.isUserAdmin ? 'cursor-move' : ''}`}
            key={policy.name}
            onDragStart={() => onDragStart(index)}
            onDrop={onDrop}
            onDragOver={(e) => onDragOver(e, index)}
            draggable={user?.isUserAdmin}
            data-testid={`draggable-${index}`}
          >
            <FaGripVertical size="24" />
            <kbd className="w-12 h-12 flex items-center justify-center">
              <div className="font-bold">{index + 1}</div>
            </kbd>
            <div> {policy.name}</div>
          </div>
        ))}
      </div>
      <div className="flex gap-2 justify-end mt-4">
        {errorMsg ? (
          <div className="text-error text-lg">{errorMsg}</div>
        ) : (
          <SubmitButtons
            onClose={onClose}
            onSubmit={() => onSubmit(policies)}
            submitLabel="Confirm"
            isLoading={putPolicyRank?.isPending}
            submitStyle="h-12 ml-2"
          />
        )}
      </div>
    </div>
  );
};

export { PolicyPriority };
