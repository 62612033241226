import { Incident } from '@api/types';
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6';
import { FaTimesCircle } from 'react-icons/fa';

type incidentStatus = {
  incident?: Incident;
  style?: string;
  size?: 'small' | 'large';
};

const getIconColor = (status?: string) => {
  if (status === 'closed') return 'bg-success-light';
  if (status === 'open') return 'bg-error-light';
  return 'bg-bg-1';
};
const IncidentStatus = ({
  incident,
  style,
  size = 'large'
}: incidentStatus) => {
  const sizeGuide = {
    small: { icon: 12, padding: 'p-2' },
    large: { icon: 24, padding: 'p-3' }
  };
  const iconSize = sizeGuide[size].icon;
  return (
    <div
      className={`${style} ${getIconColor(incident?.status)} ${sizeGuide[size].padding} my-1 rounded-xl flex max-h-[50px]`}
    >
      {incident?.status === 'closed' && (
        <FaCircleCheck size={iconSize} className="text-fg-2" />
      )}
      {incident?.status === 'open' && (
        <FaCircleExclamation size={iconSize} className="text-fg-2" />
      )}
      {!incident?.status && (
        <FaTimesCircle size={iconSize} className="text-fg-2" />
      )}
    </div>
  );
};

export { IncidentStatus };
