import { useState } from 'react';
import { Dialog } from '@components/shared/Dialog';
import { useApiService } from '@api/services';
import { useUser } from '@context/UserProvider';
import { Loader } from '@components/shared/Loader';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import {
  OrganizationMember as OrgMember,
  OrganizationMemberRole
} from '@api/types';
import { useForm } from 'react-hook-form';
import { Header } from '@components/shared/Header';
import { OrganizationMember } from '@components/Settings/Members/OrganizationMember';

type MembersHookResult = {
  data?: OrgMember[] | undefined;
  isLoading: boolean;
  refetch: () => Promise<any>;
};

const OrganizationMembers = () => {
  const { user } = useUser();
  const { useMembers, postInvitation } = useApiService();
  const {
    data: members,
    isLoading,
    refetch: refetchMembers
  } = useMembers() as MembersHookResult;
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [inviteMemberForm, setInviteMemberForm] = useState({
    email: '',
    role: 'moderator'
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: inviteMemberForm });
  const [submitResult, setSubmitResult] = useState(false);
  const regex = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,6}$/;

  const toggleInviteMemberModal = () => {
    setShowInviteMemberModal(!showInviteMemberModal);
    setInviteMemberForm({ email: '', role: 'moderator' });
    reset(inviteMemberForm);
  };

  const inviteMemberHandler = (data: any) => {
    postInvitation.mutate(
      {
        email: data.email,
        role: data.role as OrganizationMemberRole
      },
      {
        onSuccess: async () => {
          toggleInviteMemberModal();
          await refetchMembers();
          reset(inviteMemberForm);
        },
        onError: (err: any) => {
          setSubmitResult(err.message);
        }
      }
    );
  };

  if (!user?.isUserAdmin) return null;
  if (isLoading) return <Loader />;

  return (
    <div>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Members</h2>
        </div>
        <Button
          type="primary"
          onClick={toggleInviteMemberModal}
          title="Invite Member"
        />
      </Header>
      <div
        className="mb-4 grid gap-4 px-8 pt-8"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))'
        }}
      >
        {members?.map((member) => (
          <OrganizationMember
            member={member}
            refetchMembers={refetchMembers}
            key={member?.id}
          />
        ))}
      </div>
      <Dialog
        dialogStyle="w-1/3 bg-custom-bg"
        show={showInviteMemberModal}
        close={toggleInviteMemberModal}
      >
        <div>
          <h4 className="text-cta">Invite Member</h4>
        </div>
        <div>
          <form>
            <div>
              <div className="pt-4 font-bold">Email address</div>
              <input
                type="email"
                placeholder="Enter email..."
                className="roundedContainer w-full mt-2 bg-custom-bg"
                {...register('email', {
                  required: 'This is required',
                  validate: (value) => {
                    return regex.test(value)
                      ? true
                      : 'Please provide a valid email address.';
                  }
                })}
              />
              {errors.email && (
                <p className="text-alert">{errors.email.message}</p>
              )}
              <div className="textMuted mt-1">
                Member will receive an email with a link to join the
                organization.
              </div>
            </div>
            <div>
              <div className="font-bold pt-3 bg-custom-bg">Role</div>
              <select
                defaultValue={inviteMemberForm.role}
                className="roundedContainer w-full mt-2 h-9 bg-custom-bg"
                {...register('role', { required: 'This is required' })}
              >
                <option value={'administrator'}>Administrator</option>
                <option value={'moderator'}>Moderator</option>
              </select>
              {submitResult && <p className="text-red">{submitResult}</p>}
            </div>
          </form>
        </div>
        <div className="flex gap-2 justify-end mt-4">
          <SubmitButtons
            onClose={toggleInviteMemberModal}
            onSubmit={handleSubmit(inviteMemberHandler)}
            submitLabel="Send Invitation"
            isLoading={postInvitation?.isPending}
            submitStyle="font-bold"
          />
        </div>
      </Dialog>
    </div>
  );
};

export default OrganizationMembers;
