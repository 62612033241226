import { useState, DragEvent } from 'react';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dialog } from '@components/shared/Dialog';
import { FaUserCircle } from 'react-icons/fa';
import { FaGripVertical } from 'react-icons/fa6';
import { Action } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { TbVersionsFilled } from 'react-icons/tb';

const ActionField = ({
  action,
  index,
  dragStart,
  drop,
  dragOver,
  isLoading,
  submitArchive,
  isUserAdmin = false,
  platformId,
  platformSection
}: {
  action: Action;
  index: number;
  dragStart: (i: number) => void;
  drop: (e: DragEvent<HTMLDivElement>) => void;
  dragOver: (e: DragEvent<HTMLDivElement>, i: number) => void;
  isLoading: boolean;
  submitArchive: (hint?: string, status?: boolean) => void;
  isUserAdmin?: boolean;
  platformId: string;
  platformSection: string;
}) => {
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const navigate = useNavigate();
  const isDraggable = isUserAdmin && !action?.archived;
  return (
    <div
      className={`flex justify-between py-6 px-8 hover:bg-highlight border-b border-border h-24 ${isDraggable ? 'cursor-move' : ''}`}
      key={action.name}
      onDragStart={() => dragStart(index)}
      onDrop={(e) => drop(e)}
      onDragOver={(e) => dragOver(e, index)}
      draggable={isDraggable}
    >
      <div className="flex items-center gap-8">
        <div className="flex gap-8">
          <FaGripVertical size="16" />
          <kbd className="ml-0 w-12 h-12 flex items-center justify-center">
            <div className="font-bold">{index + 1}</div>
          </kbd>
        </div>
        <div className="min-w-40">
          <div className="line-clamp-1 text-lg font-bold">{action.name}</div>
          <div className="text-sm">
            {action.contentDecision ?? action.authorDecision}
          </div>
        </div>
        <div className="hidden xl:block">
          {action.authorDecision && (
            <div className="min-w-24 gap-4 flex">
              <FaUserCircle size={20} />
              <div>Author</div>
            </div>
          )}
          {action.contentDecision && (
            <div className="min-w-24 gap-3 flex">
              <TbVersionsFilled size={22} />
              <div>Content</div>
            </div>
          )}
        </div>
        <div className="min-w-40 max-w-40 hidden xl:block">
          Hint: {action.hint ?? 'n/a'}
        </div>
        <div className="hidden lg:block text-left max-h-24 py-5 overflow-hidden">
          Complex types:{' '}
          <span className="text-wrap">
            {!action?.complexTypes?.length
              ? 'All'
              : action.complexTypes.join(', ')}
          </span>
        </div>
      </div>
      {!!isUserAdmin && (
        <div className="flex flex-row gap-2 h-8 mt-2">
          <Button
            title="Edit"
            type="secondarySmall"
            onClick={() =>
              navigate(
                `/settings/action/${action.hint}?pid=${platformId}&pid-setting=${platformSection}`
              )
            }
          />
          <Button
            title={action?.archived ? 'Restore' : 'Archive'}
            type="secondarySmall"
            onClick={() => setShowArchive(true)}
          />
        </div>
      )}
      <Dialog show={showArchive} close={() => setShowArchive(false)}>
        <div>
          <h2 className="mb-6">Are you sure?</h2>
          Archiving will {action?.archived ? 'enable' : 'disable'} this action
          for your account. You can restore at any time by clicking the{' '}
          {action?.archived ? 'restore' : 'archive'} button.
          <div className="flex justify-end w-full">
            <SubmitButtons
              onSubmit={() => submitArchive(action?.hint, action?.archived)}
              submitLabel={'Confirm'}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export { ActionField };
