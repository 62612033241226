import { useState } from 'react';
import { PlatformTime } from '@components/shared/PlatformTime';
import { IncidentStatus } from '@components/shared/IncidentStatus';
import { Dialog } from '@components/shared/Dialog';
import { ViewEvent } from '@components/shared/CaseWrappers/ViewEvent';
import { ContentCaseShort, ContentCase } from '@api/types';
import { BulkReviewCase } from '@typeDef/Review';
import { Button } from '@components/shared/Buttons';
import { ContentFields } from '@components/shared/Content/Fields';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { FaEye } from 'react-icons/fa6';
import { TbVersionsFilled } from 'react-icons/tb';
import { FaUserCircle } from 'react-icons/fa';
import { InteractiveLabel } from '@components/shared/InteractiveLabel';
import { capitalizeFirstLetter } from '@utils/string';

const GridCard = ({
  contentCase,
  selectedCS,
  setSelectedCS,
  isNotQA = true,
  queueId,
  showTranslate = true
}: {
  contentCase: ContentCaseShort;
  selectedCS?: Map<string, BulkReviewCase | ContentCase>;
  setSelectedCS?: (selectedCS: Map<string, any>) => void;
  isNotQA?: boolean;
  queueId?: string;
  showTranslate?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const content = contentCase?.contents?.at(-1);
  const incident = contentCase?.incidents?.at(-1);
  const selected = selectedCS?.has(contentCase?.cid);
  const navigate = useNavigate();
  const { platform } = useUser();

  const onClick = () => {
    if (!isNotQA) return;
    selected
      ? selectedCS?.delete(contentCase?.cid)
      : selectedCS?.set(contentCase?.cid, contentCase);
    setSelectedCS?.(new Map(selectedCS));
  };

  return (
    <div
      className={`border-border border rounded-md p-6 bg-custom-bg hover:shadow-md hover:bg-highlight ${selected && 'outline outline-cta bg-highlight'} ${isNotQA && 'cursor-pointer'} flex flex-col`}
    >
      <div
        className="flex flex-row w-full"
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <div className="flex items-center justify-between mb-2 w-full">
          {!Object.keys(contentCase).includes('_primary_term') && (
            <div className="flex gap-2 items-center">
              <IncidentStatus incident={incident} />
              <div>
                <div className="font-bold text-lg">
                  {incident?.status
                    ? `Incident ${incident?.status}`
                    : 'No Incident'}
                </div>
                <PlatformTime dt={contentCase.ts} />
              </div>
            </div>
          )}

          <div className="flex gap-2 items-start">
            <Button
              type="icon h-8 w-8"
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
              }}
              hiddenTitle="View More"
            >
              <FaEye className="min-w-[10px]" data-testid="more" />
            </Button>
            <Button
              type="primarySmall justify-center"
              onClick={() => {
                navigate(
                  `/queues/${queueId ?? 'noqueue'}/review/event/${encodeURIComponent(contentCase.cid)}/${encodeURIComponent(contentCase.ct)}?pid=${platform?.id}`
                );
              }}
              hiddenTitle="Moderate case"
            >
              Moderate
            </Button>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col h-[400px] overflow-auto items-center justify-between overflow-y-visible w-full text-left grow border-t border-border"
        onClick={onClick}
      >
        <div className="overflow-y-visible break-words cursor-default">
          <ContentFields
            fields={content?.fields}
            showTranslate={showTranslate}
          />
        </div>
      </div>

      <footer className="flex flex-row border-t border-border pt-4 justify-start gap-4">
        <InteractiveLabel
          tooltip="Click to copy"
          title={capitalizeFirstLetter(contentCase.ct)}
          style="font-semibold"
        />
        <InteractiveLabel
          tooltip="Click to copy"
          title={contentCase.cid}
          icon={<TbVersionsFilled className="min-w-6" />}
          style="lg:w-48 xl:w-32"
        />
        {content?.author && (
          <InteractiveLabel
            tooltip="See author's cases"
            onClick={() => navigate(`/explore?author=${content?.author || ''}`)}
            title={content?.author}
            icon={<FaUserCircle className="min-w-6" />}
            style="lg:w-48 xl:w-36"
          />
        )}
      </footer>

      <Dialog
        show={show}
        close={() => setShow(false)}
        dialogStyle="w-2/3 bg-custom-bg relative cursor-default"
      >
        <div className="px-6 pb-2">
          <ViewEvent contentCase={contentCase} setShow={setShow} />
        </div>
      </Dialog>
    </div>
  );
};

export { GridCard };
