import { useUser } from '@context/UserProvider';
import { Header } from '@components/shared/Header';
import { useApiService } from '@api/services';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from '@components/shared/Loader';
import { Queue } from '@api/types';
import { Accordion } from '@components/shared/Accordion';
import { FaFolderOpen } from 'react-icons/fa';
import { Dropdown } from '@components/shared/Dropdown';

const QueuesGrid = ({
  queues,
  platformId,
  platformSection
}: {
  queues: Queue[];
  platformId: string;
  platformSection: string;
}) => (
  <div
    className="mb-4 grid gap-4 pt-8"
    style={{
      gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))'
    }}
  >
    {queues.map((queue: Queue) => (
      <NavLink
        className="text-text-white bg-bg-3 rounded-lg flex flex-col gap-3 p-6 text-left flex-grow min-w-[260px]"
        to={`${queue.id.toString()}?pid=${platformId}&pid-setting=${platformSection}`}
        key={queue.id}
      >
        <h5>{queue.name}</h5>
      </NavLink>
    ))}
  </div>
);

const QueuesSettings = () => {
  const { useQueues } = useApiService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid') || '';
  const platformSection = searchParams.get('pid-setting') || '';
  const {
    data: queues,
    isLoading,
    error
  } = useQueues({ platformId: platformSection });
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() == platformId);

  const templates: { [key: string]: any } = {
    'Open incident': {
      name: 'Open incident',
      code: 'noqueue',
      bucketLabel: 'Potential Violations',
      criteriaSpec: {
        include: [
          {
            op: 'has_incident_status',
            status: 'open'
          },
          {
            op: 'has_level',
            level: 0
          }
        ],
        exclude: [
          {
            op: 'has_incident_causes',
            causes: ['appeal']
          }
        ]
      }
    },
    Escalations: {
      name: 'Escalations',
      code: 'noqueue',
      bucketLabel: 'Escalations',
      criteriaSpec: {
        include: [
          {
            op: 'has_incident_status',
            status: 'open'
          },
          {
            op: 'has_level_greater_than',
            levelBound: 0
          }
        ],
        exclude: []
      }
    },
    Appeals: {
      name: 'Appeals',
      code: 'noqueue',
      bucketLabel: 'Appeals',
      criteriaSpec: {
        include: [
          {
            op: 'has_incident_status',
            status: 'open'
          },
          {
            op: 'has_incident_causes',
            causes: ['appeal']
          }
        ],
        exclude: []
      }
    },
    QA: {
      name: 'QA',
      code: 'noqueue',
      bucketLabel: 'QA',
      criteriaSpec: {
        include: [
          {
            op: 'has_incident_status',
            status: 'closed'
          }
        ],
        exclude: []
      }
    }
  };

  const templateList = ['Open incident', 'Escalations', 'Appeals', 'QA'];

  if (isLoading) return <Loader />;
  if (error || !queues || !platform) return <div>Please try later</div>;

  const enabledQueues = queues.filter((queue: Queue) => !queue.disabled);
  const archivedQueues = queues.filter((queue: Queue) => queue.disabled);

  return (
    <>
      <Header>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-col pt-2">
            <div className="text-cta font-semibold">{platform.name}</div>
            <h2 className="mb-3 text-[28px] lg:text-[32px]">Queues</h2>
          </div>
          <Dropdown
            buttonStyle="primary"
            listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
            title="Create new queue +"
            list={templateList}
            arrow={false}
            onAction={(option: string) =>
              navigate(
                `newqueue?pid=${platformId}&pid-setting=${platformSection}`,
                { state: templates[option] }
              )
            }
          />
        </div>
      </Header>
      <div className="px-8">
        <QueuesGrid
          queues={enabledQueues}
          platformId={platformId}
          platformSection={platformSection}
        />
        {!!archivedQueues?.length && (
          <div className="pt-4">
            <Accordion
              title="Archived"
              icon={<FaFolderOpen size={24} />}
              hasDivider={false}
            >
              <QueuesGrid
                queues={archivedQueues}
                platformId={platformId}
                platformSection={platformSection}
              />
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default QueuesSettings;
