import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

const TextContent = ({ val }: { val: string }) => {
  return (
    <div key={val} className="textWrapping pr-4 relative">
      <div className="text-left">
        <div
          className="prose max-w-full text-text-white"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(marked.parse(val) as string)
          }}
        />
      </div>
    </div>
  );
};

export { TextContent };
