import { useEffect, useState, FormEvent } from 'react';
import { SimpleType, DomainObject, IngestContentRequest } from '@api/types';
import { Button } from '@components/shared/Buttons';
import { useApiService } from '@api/services';
import { v4 as uuidv4 } from 'uuid';
import { Dialog } from '@components/shared/Dialog';
import { MdEdit, MdDelete } from 'react-icons/md';
import { Dropdown } from '@components/shared/Dropdown';

type Field = {
  id: string;
  type: string;
  src?: string;
};

const defaultMsg = '-- Please select one --';
const IngestCSV = ({
  domainObject,
  domainObjects,
  data
}: {
  domainObject: DomainObject;
  domainObjects?: DomainObject[];
  data: any;
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { postContentInBulk } = useApiService();
  const [isIngestionOK, setIsIngestionOK] = useState<boolean>();
  const [showAddParent, setShowAddParent] = useState<boolean>(false);
  const [showAddMetadata, setShowAddMetadata] = useState<boolean>(false);
  const [showAddAuthor, setShowAddAuthor] = useState<boolean>(false);
  const [parent, setParent] = useState<any>();
  const [metadata, setMetadata] = useState<Set<string>>(new Set());
  const [author, setAuthor] = useState<any>();

  const [fields, setFields] = useState<Field[]>([]);
  const [csvKeys, setCsvKeys] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (data) {
      const fieldsList = Object.keys(data[0]).map((k: string) => ({
        id: k,
        type: guessFieldType(data[0][k as any])
      }));
      setFields(fieldsList);
      setCsvKeys(new Set(fieldsList.map((x: any) => x.id)));
    }
  }, [data]);

  const addField = () => setFields([...fields, { id: '', type: '' }]);

  const addMetadata = (value: string) => {
    metadata.add(value);
    setMetadata(new Set([...metadata]));
  };

  const onChangeParent = (value: string, name: string) =>
    setParent({ ...parent, [name]: value });

  const onChangeAuthor = (value: string) => setAuthor(value);

  const onChangeField = (value: string, name: string, index: number) => {
    const data = [...fields] as any;
    data[index][name] = value;

    if (name === 'id') {
      data[index]['type'] = guessFieldType(data[0][value]);
    }

    setFields(data);
  };

  const removeFields = (index: number) => {
    const data = [...fields];
    data.splice(index, 1);
    setFields(data);
  };

  const guessFieldType = (val: string, type?: string) => {
    if (type) return type;

    const isImage = new RegExp('^https?://.*/[^/]*.(jpg|jpeg|png|webp).*');
    const isVideo = new RegExp('^https?://.*/[^/]*.(mp4|mov).*');
    const isUrl = new RegExp('^https?://.');

    if (isImage.test(val)) return SimpleType.IMAGE;
    if (isVideo.test(val)) return SimpleType.VIDEO;
    if (isUrl.test(val)) return SimpleType.URI;
    return SimpleType.TEXT;
  };

  const getFields = (field: Field, row: any) => {
    if (row[field.id])
      return {
        ...field,
        id: field.id.replace(/[^0-9a-zA-Z_-]+/g, '_'),
        src: row[field.id]
      };
  };

  const getContents = (csvToJson: any) =>
    csvToJson
      .map((row: any) => ({
        id: uuidv4(),
        type: domainObject.type,
        ...(parent && {
          parent: {
            id: row[parent.id],
            type: parent.type
          }
        }),
        ...(!!metadata.size && {
          metadata: [...metadata].map((m: string) => ({
            id: m,
            value: row[m]
          }))
        }),
        ...(author && { author: row[author] }),
        fields: fields.map((field) => getFields(field, row)).filter((x) => x)
      }))
      .filter((field: IngestContentRequest) => !!field.fields?.length);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    postContentInBulk.mutate(
      {
        bulk: uuidv4(),
        contents: getContents(data)
      },
      {
        onSettled: async () => setShowDialog(true),
        onSuccess: async () => {
          setIsIngestionOK(true);
        },
        onError: async () => setIsIngestionOK(false)
      }
    );
  };

  const onSelectAction = (type: string) => {
    if (type === 'Parent') setShowAddParent(true);
    if (type === 'Metadata') setShowAddMetadata(true);
    if (type === 'Field') addField();
    if (type === 'Author') setShowAddAuthor(true);
  };

  if (!data) return null;

  return (
    <>
      <div>
        <div className="w-full flex justify-end gap-3">
          <Dropdown
            buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
            listStyle="border border-border absolute rounded-md overflow-hidden min-w-24"
            list={['Field', 'Parent', 'Metadata', 'Author']}
            title={'Add new'}
            onAction={onSelectAction}
          />
        </div>
        <form
          data-testid="form"
          onSubmit={handleSubmit}
          className="flex gap-4 flex-col"
        >
          <div className="text-lg">Fields</div>
          <div>
            {fields?.map((field: Field, index: number) => (
              <div className="flex gap-3 mb-3 w-full" key={index}>
                <Dropdown
                  buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
                  mainClass="w-full"
                  listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
                  list={[...csvKeys]}
                  title={field.id || defaultMsg}
                  onAction={(option: string) =>
                    onChangeField(option, 'id', index)
                  }
                />

                <Dropdown
                  buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
                  listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
                  mainClass="w-32"
                  list={Object.values(SimpleType)}
                  title={field.type}
                  onAction={(option: string) =>
                    onChangeField(option, 'type', index)
                  }
                />

                <div className="flex justify-end gap-2 ml-2 items-center">
                  <MdDelete
                    onClick={() => removeFields(index)}
                    className="icon"
                    data-testid="delete field"
                    size="28"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="flex gap-6 flex-col">
            {parent && (
              <div className="flex gap-3 flex-col">
                <div className="text-lg flex gap-3">
                  Parent
                  <MdEdit
                    className="icon"
                    size="24"
                    onClick={() => setShowAddParent(true)}
                  />
                  <MdDelete
                    onClick={() => setParent(null)}
                    className="icon"
                    size="24"
                  />
                </div>
                <div className="min-w-fit flex gap-3 items-center">
                  <div className="border border-border rounded-md p-2 gap-2 flex">
                    <span>ID: {parent.id}</span>
                    <span>Type: {parent.type}</span>
                  </div>
                </div>
              </div>
            )}
            {author && (
              <div className="flex gap-3 flex-col">
                <div className="text-lg flex gap-3">
                  Author
                  <MdEdit
                    className="icon"
                    size="24"
                    onClick={() => setShowAddAuthor(true)}
                  />
                  <MdDelete
                    onClick={() => setAuthor(null)}
                    className="icon"
                    size="24"
                  />
                </div>
                <div className="min-w-fit flex gap-3 items-center">
                  <div className="border border-border rounded-md p-2 gap-2 flex">
                    <span>{author}</span>
                  </div>
                </div>
              </div>
            )}
            {!!metadata.size && (
              <div className="flex gap-3 flex-col">
                <div className="text-lg flex gap-3">
                  Metadata
                  <MdEdit
                    className="icon"
                    size="22"
                    onClick={() => setShowAddMetadata(true)}
                  />
                  <MdDelete
                    onClick={() => setMetadata(new Set())}
                    className="icon"
                    size="24"
                  />
                </div>
                <div className="min-w-fit flex gap-3 items-center">
                  <div className="border border-border rounded-md p-2">
                    {[...metadata].join(', ')}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-end py-3 gap-2">
            <Button onClick={() => {}} type="primary" title="Ingest content" />
          </div>
        </form>
      </div>

      <Dialog
        show={showAddMetadata}
        close={() => setShowAddMetadata(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        <div className="flex flex-col gap-4">
          <h5>Select the Metadata keys</h5>

          {[...metadata]?.map((m: string) => (
            <div className="flex items-center gap-3" key={m}>
              <Dropdown
                buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
                mainClass="w-full"
                listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
                list={[...csvKeys]}
                title={m || defaultMsg}
                onAction={(option: string) => addMetadata(option)}
              />
              <MdDelete
                onClick={() => {
                  metadata.delete(m);
                  setMetadata(new Set([...metadata]));
                }}
                className="icon h-7"
                size="28"
              />
            </div>
          ))}
          <Dropdown
            buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
            mainClass="w-full"
            listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
            list={[...csvKeys].filter((x: string) => !metadata.has(x))}
            title={defaultMsg}
            onAction={(option: string) => addMetadata(option)}
          />
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddMetadata(false)}
              title="Confirm"
              type="primary"
              style="h-10"
              disabled={!metadata.size}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        show={showAddParent}
        close={() => setShowAddParent(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        <div className="flex flex-col gap-4">
          <h5>Select the parent field and type</h5>
          <div className="w-full flex gap-3">
            <Dropdown
              buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
              mainClass="w-full"
              listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
              list={[...csvKeys]}
              title={parent?.id || '-- Please select the key --'}
              onAction={(option: string) => onChangeParent(option, 'id')}
            />

            <Dropdown
              buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
              mainClass="w-full"
              listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
              list={domainObjects || []}
              title={parent?.type || '-- Please select the Type --'}
              onAction={(option: DomainObject) =>
                onChangeParent(option.type, 'type')
              }
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddParent(false)}
              title="Confirm"
              type="primary"
              style="h-10"
              disabled={!parent?.id || !parent?.type}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        show={showAddAuthor}
        close={() => setShowAddAuthor(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        <div className="flex flex-col gap-4">
          <h5>Select the Author</h5>
          <div className="w-full flex gap-3">
            <Dropdown
              buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
              mainClass="w-full"
              listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
              list={[...csvKeys]}
              title={author || '-- Please select the Author --'}
              onAction={onChangeAuthor}
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddAuthor(false)}
              title="Confirm"
              type="primary"
              style="h-10"
              disabled={!author}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        show={showDialog}
        close={() => setShowDialog(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        {isIngestionOK ? (
          <>
            <div className="font-semibold text-xl mb-4">Content submitted</div>
            <div className="text-lg">
              Your content should appear in the Explore page shortly
            </div>
          </>
        ) : (
          <>
            <div className="font-semibold text-xl mb-4">Error found</div>
            <div className="text-lg">Please try again</div>
          </>
        )}
      </Dialog>
    </>
  );
};

export { IngestCSV, defaultMsg };
