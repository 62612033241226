import { Button, SubmitButtons } from '@components/shared/Buttons';
import { Dialog } from '@components/shared/Dialog';
import { useState } from 'react';
import { Queue } from '@api/types';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';

const QueueArchive = ({
  platformId,
  queue
}: {
  platformId: string;
  queue: Queue;
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const { updateQueueStatus } = useApiService();
  const queryClient = useQueryClient();

  const archiveQueue = (archive: boolean) => {
    updateQueueStatus.mutate(
      {
        platformId,
        queueId: queue.id,
        disabled: archive
      },
      {
        onSuccess: async () =>
          queryClient
            .invalidateQueries({ queryKey: ['queues', platformId] })
            .then(() => setShowConfirmationDialog(false)),
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  const ArchiveButton = () => (
    <Button
      hiddenTitle="Archive"
      onClick={() => setShowConfirmationDialog(true)}
      type="secondary"
    >
      Archive
    </Button>
  );
  const RestoreButton = () => (
    <Button
      hiddenTitle="Restore"
      onClick={() => archiveQueue(false)}
      type="secondary"
    >
      Restore
    </Button>
  );
  return (
    <>
      <div className="flex-col">
        {queue.disabled ? <RestoreButton /> : <ArchiveButton />}
      </div>
      <Dialog
        show={showConfirmationDialog}
        close={() => setShowConfirmationDialog(false)}
      >
        <div className="p-4 max-h-[50%]">
          <h2 className="mb-6">Are you sure?</h2>
          Archiving will disable this queue for your account. You can restore at
          any time by clicking the restore button in the archived page.
          <div className="flex gap-2 mt-6 justify-end">
            <SubmitButtons
              onClose={() => setShowConfirmationDialog(false)}
              onSubmit={() => archiveQueue(true)}
              submitLabel="Confirm"
              isLoading={updateQueueStatus?.isPending}
              submitStyle="font-bold py-2"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export { QueueArchive };
