import { UserHistoryCard } from './UserHistoryCard';
import { Button } from '@components/shared/Buttons';
import { FiCopy } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { ElementType } from 'react';

type UserInfoProps = {
  userId?: string;
  majorStrikes?: number;
  minorStrikes?: number;
  incidents?: number;
  cases?: number;
  strikes?: number;
  setShow?: (val: boolean) => void;
  headerType?: ElementType;
};

const UserInfo = ({
  userId = '',
  majorStrikes = 0,
  minorStrikes = 0,
  incidents = 0,
  cases = 0,
  strikes = 0,
  setShow,
  headerType = 'h5'
}: UserInfoProps) => {
  const HeaderTag = headerType;

  return (
    <div>
      <div className="flex mb-4 items-start">
        <FaUserCircle className="min-w-6 mr-2" />
        <HeaderTag className="truncate flex-grow">
          <span>{userId || 'Unknown'}</span>
        </HeaderTag>
        <Button
          type="icon"
          style="h-7 w-7"
          tooltip="Copy user ID"
          onClick={() => navigator.clipboard.writeText(userId)}
        >
          <FiCopy size="16" />
        </Button>
      </div>
      <div className="flex flex-col min-w-[160px] ">
        <div className="flex flex-col pb-4">
          <UserHistoryCard
            url={`/explore?author=${encodeURIComponent(userId)}&resolutions=enforced`}
            title="Strikes"
            total={strikes}
            setShow={setShow}
          >
            <div className="flex flex-row gap-2 ml-[5px] mt-0.5">
              <div className="flex flex-row gap-2 justify-between items-baseline">
                <div className="text-xs font-semibold">{majorStrikes}</div>
                <div className="text-xs font-normal">Major</div>
              </div>
              <div className="flex flex-row gap-2 justify-between items-baseline">
                <div className="text-xs font-semibold">{minorStrikes}</div>
                <div className="text-xs font-normal">Minor</div>
              </div>
            </div>
          </UserHistoryCard>

          <UserHistoryCard
            url={`/explore?author=${encodeURIComponent(userId)}&status=incident`}
            title="Incidents"
            total={incidents}
            setShow={setShow}
          />
          <UserHistoryCard
            url={`/explore?author=${encodeURIComponent(userId)}`}
            title="Cases"
            total={cases}
            setShow={setShow}
          />
        </div>
        <div className="border-b border-border hidden 2xl:block" />
      </div>
    </div>
  );
};

export { UserInfo };
