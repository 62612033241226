import { Header } from '@components/shared/Header';
import { FaChevronLeft } from 'react-icons/fa6';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation
} from 'react-router-dom';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import {
  Queue as QueueInfo,
  OrderDirection,
  CriterionOperation,
  IncidentStatus,
  IncidentCause,
  IncidentResolution
} from '@api/types';
import QueueCriteria from '@components/Settings/QueueSettings/QueueCriteria';
import { useEffect, useState } from 'react';
import { QueueCriteriaProp } from '@typeDef/queues';
import { Dialog } from '@components/shared/Dialog';
import { Dropdown } from '@components/shared/Dropdown';
import { useUser } from '@context/UserProvider';
import { QueueArchive } from '@components/Settings/QueueSettings/QueueArchive';
import { snakeCaseToWords } from '@utils/string';
import { StringCriteria } from '@components/Settings/QueueSettings/Inputs/StringCriteria';
import { NumberCriteria } from '@components/Settings/QueueSettings/Inputs/NumberCriteria';
import { DropdownCriteria } from '@components/Settings/QueueSettings/Inputs/DropdownCriteria';
import { ThresholdCriteria } from '@components/Settings/QueueSettings/Inputs/ThesholdCriteria';
import { useAnalytics } from '@context/AnalyticsProvider';

const Queue = () => {
  const location = useLocation();
  const template = location.state || {};
  const newQueue = Object.keys(template).length ?? 0;
  const { useQueueDescription, useQueues, useEditQueue, useCreateQueue } =
    useApiService();
  const { domainObjects, policies } = useUser();
  const { sendEvent } = useAnalytics();

  const [showNewCriteria, setShowNewCriteria] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [pendingChanges, setPendingChanges] = useState(new Map());
  const [combinedCriteria, setCombinedCriteria] = useState<any>([]);
  const [pendingOp, setPendingOp] = useState<CriterionOperation>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { queueId } = useParams();
  const platformId = searchParams.get('pid') || '';
  const platformSelection = searchParams.get('pid-setting') || '';
  const { data: queues } = useQueues({
    platformId: platformSelection
  });
  const {
    data,
    isLoading,
    error,
    refetch: refetchQueue
  } = useQueueDescription({
    queueId,
    platformId: platformSelection
  });

  const [queueData, setQueueData] = useState<QueueInfo>(
    newQueue ? template : data
  );
  const [newName, setNewName] = useState<string>(
    newQueue ? template?.name : data?.name
  );

  useEffect(() => {
    // make sure queue is initialised properly
    if (data?.name && data?.name !== newName) {
      setNewName(data?.name);
      !newQueue && setQueueData(data);
    }
  }, [data]);

  const navigateBack = () =>
    navigate(
      `/settings/queues?pid=${platformId}&pid-setting=${platformSelection}`
    );

  const criteriaMapping = {
    [CriterionOperation['HAS_COMPLEX_TYPES']]: {
      component: DropdownCriteria,
      payloadKey: 'complexTypes',
      listData: domainObjects?.map((domainObject) => domainObject.type),
      description: 'Filter by content types sent by your service.'
    },
    [CriterionOperation['HAS_NO_INCIDENTS']]: {
      component: StringCriteria,
      payloadKey: '',
      singleValue: true,
      description:
        'Filter by whether a case has or doesn’t have an incident flag.'
    },
    [CriterionOperation['HAS_INCIDENT_STATUS']]: {
      component: DropdownCriteria,
      payloadKey: 'status',
      listData: Object.values(IncidentStatus),
      singleValue: true,
      description: 'Filter by the status of case incidents.'
    },
    [CriterionOperation['HAS_INCIDENT_CAUSES']]: {
      component: DropdownCriteria,
      payloadKey: 'causes',
      listData: Object.values(IncidentCause),
      description:
        'Filter by the cause of an  incident, e.g. appeal, report, etc.'
    },
    [CriterionOperation['HAS_INCIDENT_RESOLUTIONS']]: {
      component: DropdownCriteria,
      payloadKey: 'resolutions',
      listData: Object.values(IncidentResolution),
      description:
        'Filter by the resolution of an incident, e.g. enforced, dismissed, etc.'
    },
    [CriterionOperation['HAS_POTENTIAL_VIOLATIONS']]: {
      component: DropdownCriteria,
      payloadKey: 'potentialViolations',
      listData: policies,
      description: 'Filter by the potential policy violations.'
    },
    [CriterionOperation['HAS_VIOLATIONS']]: {
      component: DropdownCriteria,
      payloadKey: 'violations',
      listData: policies,
      description: 'Filter by actual policy violations'
    },
    [CriterionOperation['HAS_TAGS']]: {
      component: StringCriteria,
      payloadKey: 'tags',
      singleValue: false,
      description: 'Filter by tags provided to the Checkstep API'
    },
    [CriterionOperation['HAS_LEVEL']]: {
      component: NumberCriteria,
      payloadKey: 'level',
      description: 'Filter by level. Levels increase with escalation actions.'
    },
    [CriterionOperation['HAS_LEVEL_GREATER_THAN']]: {
      component: NumberCriteria,
      payloadKey: 'levelBound',
      description: 'Select only cases with a level greater than a value.'
    },
    [CriterionOperation['SAMPLE']]: {
      component: NumberCriteria,
      payloadKey: 'threshold',
      description: 'Set random sampling rate for queue.'
    },
    [CriterionOperation['SAMPLE_PER_QUEUE']]: {
      component: ThresholdCriteria,
      payloadKey: 'thresholds',
      description: 'Set Queue ID and sample value for QA.'
    },
    [CriterionOperation['HAS_QUALITY_CHECKS']]: {
      component: StringCriteria,
      payloadKey: '',
      singleValue: true,
      description: 'Filter if a case has or does not have a quality check.'
    },
    [CriterionOperation['HAS_ORIGIN_PREFIX']]: {
      component: StringCriteria,
      payloadKey: 'prefix',
      singleValue: true,
      description: 'Advanced: Set origin prefix sent to Checkstep API.'
    },
    [CriterionOperation['HAS_BEEN_AUTOMATED']]: {
      component: StringCriteria,
      payloadKey: '',
      singleValue: true,
      description: 'Select only cases automatically enforced.'
    }
  };

  const queue = queues?.find((q: QueueInfo) => q.id.toString() === queueId);

  const groupByOp = (criteria: any) => {
    const combineCriteria = (criteria: any, pendingChanges: any) => [
      ...(criteria?.exclude?.map((criterion: any) => ({
        ...criterion,
        type: 'Exclude'
      })) || []),
      ...(criteria?.include?.map((criterion: any) => ({
        ...criterion,
        type: 'Include'
      })) || []),
      ...Array.from(pendingChanges).map(([op, changes]: any) => ({
        ...changes,
        op
      }))
    ];

    // Get combined list of criteria and changes
    const combinedCriteria = combineCriteria(criteria, pendingChanges);
    const groupedCriteria: any[] = [];

    combinedCriteria.forEach((criterion) => {
      const { op, type, changes, keyName } = criterion;

      // Find or create a group based on the operation (op)
      let group = groupedCriteria.find((g) => g.op === op);
      if (!group) {
        group = { op, exclude: null, include: null };
        groupedCriteria.push(group);
      }

      // If changes exist, update the group with the changes and mark it as new
      if (changes) {
        group.exclude = { [keyName]: changes.exclude };
        group.include = { [keyName]: changes.include };
        group.new = true;
      }

      // Update the group based on the type ('Exclude' or 'Include')
      if (type === 'Exclude') {
        group.exclude = criterion;
      } else if (type === 'Include') {
        group.include = criterion;
      }
    });

    return groupedCriteria;
  };

  useEffect(() => {
    if (queueData?.criteriaSpec) {
      const groupedCriteria = groupByOp(queueData.criteriaSpec);
      setCombinedCriteria(groupedCriteria);
    }
  }, [queueData, pendingChanges]);

  const usedOps = combinedCriteria.map((crit: any) => crit.op);
  const unusedOps = Object.values(CriterionOperation).filter(
    (op) => !usedOps.includes(op)
  );

  useEffect(() => {
    setPendingOp(unusedOps[0]);
  }, [showNewCriteria]);

  if (isLoading && !queueData) return <Loader />;
  if ((error && !queueData) || !queues) return <div>Please try later</div>;

  const submitAsPending = (op: string, changes: any) => {
    setShowNewCriteria(false);
    const list = new Map(pendingChanges);
    if (list.has(op) && (changes?.remove || changes?.removePending)) {
      list.delete(op);
    } else {
      list.set(op, {
        ...changes
      });
    }
    setPendingChanges(new Map(list));
  };

  const submitChanges = () => {
    const includeMap = new Map(
      queueData?.criteriaSpec?.include?.map((item: any) => [item.op, item])
    );
    const excludeMap = new Map(
      queueData?.criteriaSpec?.exclude?.map((item: any) => [item.op, item])
    );

    pendingChanges.forEach((value, key) => {
      const { remove, keyName, changes } = value;
      const includeChange = changes?.include;
      const excludeChange = changes?.exclude;
      if (remove) {
        includeMap.delete(key);
        excludeMap.delete(key);
      }
      if (!includeChange && !excludeChange && !remove) {
        includeMap.set(key, { op: key });
      }
      if (includeChange?.length) {
        includeMap.set(key, { [keyName]: includeChange, op: key });
      }
      if (excludeChange?.length) {
        excludeMap.set(key, { [keyName]: excludeChange, op: key });
      }
    });

    queueData &&
      (newQueue ? useCreateQueue : useEditQueue)?.mutate(
        {
          content: {
            criteria: {
              include: Array.from(includeMap.values()),
              exclude: Array.from(excludeMap.values())
            },
            name: newName,
            bucketLabel: queueData?.bucketLabel,
            qa: false,
            orderDirection: OrderDirection.ASC
          },
          platform_id: parseInt(platformSelection),
          queue_id: parseInt(queueId ?? '')
        },
        {
          onSuccess: () => {
            sendEvent('clickEvent', {
              title: `edited/added ${queue?.name} queue`
            });
            newQueue && navigateBack();
            setPendingChanges(new Map());
            setShowConfirm(false);
            refetchQueue();
          }
        }
      );
  };

  const currentOp = pendingOp ?? unusedOps[0];

  return (
    <>
      <Header>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-col pt-2">
            <div className="text-cta font-semibold">Queues</div>
            <h2 className="mb-3 text-[28px] lg:text-[32px]">
              {data?.name ?? 'New queue'}
            </h2>
          </div>
          <div className="flex flex-row gap-2">
            {queue && (
              <QueueArchive platformId={platformSelection} queue={queue} />
            )}
            <Button
              disabled={
                !pendingChanges?.size && !newQueue && newName === data?.name
              }
              onClick={() => setShowConfirm(true)}
              title={newQueue ? 'Create queue' : 'Submit changes'}
              type="primary"
            />
          </div>
        </div>
      </Header>
      <div className="px-8 pt-6 flex gap-8 flex-col">
        <div className="w-full flex flex-col">
          <Button
            style="w-min flex flex-row gap-1 items-center text-cta mb-3"
            hiddenTitle="settingQueueBackBtn"
            onClick={navigateBack}
          >
            <FaChevronLeft size={14} /> Back
          </Button>
          <h3>Queue settings</h3>
        </div>
        <div className="flex gap-4 flex-col">
          <div className="font-semibold">Queue name</div>
          <input
            className="border-[1px] border-neutral-300 p-2 rounded-md bg-custom-bg"
            onChange={(e) => setNewName(e.target.value)}
            value={newName}
          />
        </div>
        <div className="flex gap-4 flex-col">
          <div className="font-semibold">Queue group label</div>
          <div className="border-[1px] border-neutral-300 p-2 rounded-md">
            {queueData?.bucketLabel}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <h3>Criteria</h3>
          <Button
            onClick={() => setShowNewCriteria(true)}
            title="Add new criteria"
            type="primarySmall"
          />
        </div>
        <div className="flex gap-4 flex-col mb-3">
          {combinedCriteria?.map(
            (criterion: QueueCriteriaProp, index: number) => (
              <QueueCriteria
                criterion={criterion}
                submitAsPending={submitAsPending}
                criteriaMapping={criteriaMapping}
                key={`${criterion.op}-${index}`}
                isNewCriteria={false}
                queues={queues}
              />
            )
          )}
        </div>
      </div>
      <Dialog show={showNewCriteria} close={() => setShowNewCriteria(false)}>
        <div>
          <h6>Add new criteria</h6>
          <div className="py-3">
            <Dropdown
              mainClass="my-3"
              buttonStyle="border-[1px] border-neutral-300 p-2 w-full rounded-md text-center min-h-10"
              listStyle="absolute z-40 border bg-custom-bg border-border w-[calc(100%-48px)] rounded-sm overflow-auto max-h-[320px]"
              itemStyle="hover:bg-transparent hover:text-cta line-clamp-1"
              title={
                <div className="flex flex-row gap-2 items-baseline">
                  <div className="whitespace-nowrap">
                    {snakeCaseToWords(currentOp)}
                  </div>
                  <div className="text-sm text-neutral-400 flex-wrap">
                    - {criteriaMapping[currentOp].description}
                  </div>
                </div>
              }
              list={unusedOps.map((val: string) => ({
                name: snakeCaseToWords(val),
                description: (criteriaMapping as any)[val].description,
                type: val
              }))}
              onAction={(option) => setPendingOp(option?.type)}
              arrow={true}
            />
            <QueueCriteria
              criterion={{
                op: pendingOp ?? unusedOps[0],
                include: [],
                exclude: []
              }}
              criteriaMapping={criteriaMapping}
              submitAsPending={submitAsPending}
              isNewCriteria={true}
              queues={queues}
            />
          </div>
        </div>
      </Dialog>
      <Dialog show={showConfirm} close={() => setShowConfirm(false)}>
        <div>
          <h6>Submit changes</h6>
          <div className="my-2">
            {!newQueue
              ? `You are about to submit ${pendingChanges?.size || 1} changes to the ${queue?.name} queue`
              : `You are about to submit a new queue with name "${newName}"`}
          </div>
          <div className="flex justify-end w-full">
            <SubmitButtons
              onSubmit={submitChanges}
              submitLabel={'Confirm'}
              isLoading={useEditQueue?.isPending}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Queue;
