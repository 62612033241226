import { useState } from 'react';
import { Appeal, CommunityReport, Inquiry } from '@api/types';
import { Reports } from '@components/Review/Content/HistoryTabs/Reports';
import { Inquiries } from '@components/Review/Content/HistoryTabs/Inquiries';
import { Appeals } from '@components/Review/Content/HistoryTabs/Appeals';
import { capitalizeFirstLetter } from '@utils/string';
import { Button } from '@components/shared/Buttons';

const HistoryTabs = ({
  inquiries,
  reports,
  appeals
}: {
  inquiries?: Array<Inquiry>;
  reports?: Array<CommunityReport>;
  appeals?: Array<Appeal>;
}) => {
  const availableHistories: any = Object.fromEntries(
    Object.entries({
      reports: reports,
      inquiries: inquiries,
      appeals: appeals
    }).filter(([, value]) => value?.length)
  );

  const [selectedKey, setSelectedKey] = useState(
    Object.keys(availableHistories)[0]
  );

  const getSelectedHistory = () => {
    switch (selectedKey) {
      case 'reports':
        return <Reports reports={reports} />;
      case 'inquiries':
        return <Inquiries inquiries={inquiries} />;
      case 'appeals':
        return <Appeals appeals={appeals} />;
    }
  };

  return (
    <>
      <div>
        {Object.keys(availableHistories).map((key: string) => (
          <Button
            key={key}
            style={`hover:bg-custom-bg mr-4 text-lg h-12 ${
              selectedKey === key && 'font-bold border-b-2 border-cta'
            }`}
            onClick={() => setSelectedKey(key)}
            title={capitalizeFirstLetter(key)}
          />
        ))}
      </div>
      {selectedKey && (
        <div className="max-h-[45vh] overflow-y-auto border-neutral-300 border-t">
          {getSelectedHistory()}
        </div>
      )}
    </>
  );
};

export { HistoryTabs };
