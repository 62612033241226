import { useState } from 'react';
import { Button } from '@components/shared/Buttons';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { useUser } from '@context/UserProvider';
import { Policy } from '@api/types';

const SelectPolicy = ({ policy, setPolicy }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { policies } = useUser();

  const onSelectPolicy = (policyName: string) => {
    const policy = policies?.find((p: Policy) => p.name === policyName);
    if (!policy) return;
    setIsOpen(false);
    setPolicy(policy);
  };
  return (
    <>
      <div className="text-sm mb-2">Policy</div>
      <Button
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        hiddenTitle="selectPolicy"
        style={`flex justify-between w-full p-2 ${policy ? 'cursor-default' : 'hover:bg-gray/10'} border border-neutral-200 rounded-t-md items-center ${!isOpen ? 'rounded-b-md' : ''}`}
      >
        <div>{policy ? policy.name : 'Select a policy'}</div>
        <span className="float-right">
          {isOpen && <AiOutlineArrowUp />}
          {!isOpen && <AiOutlineArrowDown />}
        </span>
      </Button>
      <div className="relative mb-8">
        {isOpen && (
          <div className="absolute flex flex-col zIndex-50 bg-custom-bg w-[88%] border border-border w-full max-h-60 rounded-b-md overflow-y-scroll shodow-lg">
            {policies?.map((p: Policy) => (
              <Button
                key={p.code}
                style="p-4 text-left hover:bg-highlight"
                onClick={() => {
                  onSelectPolicy(p.name);
                }}
                title={p.name}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export { SelectPolicy };
