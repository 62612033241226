import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { Loader } from '@components/shared/Loader';
import { useQueues } from '@context/QueuesProvider';
import { Pagination } from '@components/shared/Pagination';
import { ContentFilterControl } from '@components/shared/ContentFilterControl';
import { useApiService } from '@api/services';
import { GridCard } from '@components/shared/CaseWrappers/GridCard';
import { Button } from '@components/shared/Buttons';
import { useAnalytics } from '@context/AnalyticsProvider';
import { Dropdown } from '@components/shared/Dropdown';
import { Queue, Action, ContentDecisionType } from '@api/types';
import { BulkReviewCase, ViolationPayload } from '@typeDef/Review';
import { Header } from '@components/shared/Header';
import { Tray } from '@components/Review/Shared/Tray';
import { DecisionTree } from '@components/Review/DecisionTree';
import { findCommonActions } from '@components/Review/utils';
import { Checkbox } from '@components/shared/Checkbox';

const QueueBulkList = () => {
  const { sendEvent } = useAnalytics();
  const { platform, actions } = useUser();
  const { useQueueBulkList, postReviewContent, postAuthorDecision } =
    useApiService();
  const { queues, error } = useQueues();
  const [queue, setQueue] = useState<Queue>();
  const [selectedCS, setSelectedCS] = useState<Map<string, BulkReviewCase>>(
    new Map()
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(24);
  const [inputChecked, setInputChecked] = useState<boolean>(false);
  const [decisionVisible, setDecisionVisible] = useState<boolean>(false);
  const [modPayload, setModPayload] = useState<ViolationPayload>();
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const [showPolicyList, setShowPolicyList] = useState<boolean>(false);
  const [actionSelected, setActionSelected] = useState<Action | null>();
  const [showCsamModal, setShowCsamModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>();
  const [showConfirmRecap, setShowConfirmRecap] = useState(false);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false);
  const [availableActions, setAvailableActions] = useState<Action[]>();
  const [newNotes, setNewNotes] = useState<string>('');
  const { queueId } = useParams();
  const navigate = useNavigate();
  const scrollRef: any = useRef();

  const {
    data: listData,
    error: listDataError,
    refetch,
    isPending
  } = useQueueBulkList({
    queueId: Number(queueId),
    start: currentPage * pageSize,
    size: pageSize
  });

  const onSuccessfulReview = () => {
    setIsUpdatingEvent(false);
    sendEvent('clickEvent', {
      action: `bulk review ${selectedCS?.size} content on queue ${queueId}. Action: ${actionSelected?.name}`
    });
    setInputChecked(false);
    setSelectedAction(null);
    setSelectedCS(new Map());
    setShowConfirmRecap(false);
    setDecisionVisible(false);
    refetch();
  };

  const callback = {
    onSuccess: () => onSuccessfulReview()
  };

  const bulkReview = () => {
    setIsUpdatingEvent(true);
    if (!queueId || !actionSelected?.contentDecision || !selectedCS.size)
      return;
    if (!actionSelected?.authorDecision) {
      postReviewContent.mutate(
        {
          hint: actionSelected?.hint,
          content_cases: [...(selectedCS?.values() || [])].map((value) => ({
            contentId: value?.cid,
            complexType: value?.ct,
            version: value?.version || 0
          })),
          decision: actionSelected?.contentDecision as ContentDecisionType,
          violations: modPayload?.violations as any,
          queue_id: parseInt(queueId),
          notes: newNotes,
          attachments: { csam_report: null }
        },
        callback
      );
    }
    if (actionSelected?.authorDecision) {
      postAuthorDecision.mutate(
        {
          hint: actionSelected?.hint,
          queue_id: parseInt(queueId),
          violations: modPayload?.violations as any,
          decision: actionSelected?.authorDecision,
          authors: [...(selectedCS?.values() || [])].map((value) => ({
            author: value?.contents?.at(-1)?.author,
            case: {
              contentId: value?.cid,
              complexType: value?.ct,
              version: value?.version || 0
            }
          })) as any,
          notes: newNotes,
          attachments: { csam_report: null }
        },
        callback
      );
    }
  };

  useEffect(() => {
    if (queues) {
      const queue = queues?.find((q) => q.id === Number(queueId));
      if (queue) {
        setQueue(queue);
        setCurrentPage(0);
      } else {
        navigate(`/queues?pid=${platform?.id}`);
      }
    }
  }, [queueId, queues]);

  useEffect(() => {
    !listData?.totalCount &&
      !isPending &&
      navigate(`/queues?pid=${platform?.id}`);
  }, [listData]);

  useEffect(() => {
    if (selectedCS.size) {
      const allActions = [];
      for (const [, value] of selectedCS) {
        allActions.push(value.availableActions);
      }
      setAvailableActions(
        findCommonActions(allActions).map((x: string) =>
          actions?.find((action: Action) => action.hint == x)
        )
      );
    }
  }, [selectedCS]);

  const changeQueue = (option: any) => {
    option && navigate(`/queues/bulklist/${option.type}`);
  };

  useEffect(() => {
    scrollRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, [currentPage]);

  useEffect(() => {
    if (inputChecked) {
      listData?.cases?.map((x: any) => selectedCS.set(x?.cid, x));
      return setSelectedCS(new Map(selectedCS));
    }
    return setSelectedCS(new Map());
  }, [inputChecked]);

  const setSelectedAction = (action: Action | null) => {
    if (!action) return setActionSelected(null);
    if (
      (!!action.authorDecision && action.authorDecision !== 'act') ||
      (!!action.contentDecision && action.contentDecision !== 'act')
    ) {
      setShowConfirmRecap(true);
    }
    setShowPolicyList(true);
    return setActionSelected(action);
  };

  const props = {
    showPolicyList,
    setShowPolicyList,
    showCsamModal,
    payload: modPayload,
    setPayload: setModPayload,
    setShowCsamModal,
    actionSelected,
    setSelectedAction,
    selectedProperty,
    setSelectedProperty,
    modalType,
    setModalType,
    showConfirmRecap,
    setShowConfirmRecap,
    onConfirmModalClicked: bulkReview,
    isUpdatingEvent,
    availableActions,
    newNotes,
    setNewNotes
  };

  if (!queues) return <Loader />;
  if (error || listDataError) navigate(`/queues?pid=${platform?.id}`);

  return (
    <>
      <div className="flex flex-col">
        <Header>
          <h2>{`${queue?.bucketLabel} & ${queue?.name}`}</h2>
          <div className="overflow-visible relative flex gap-1 items-center">
            <span className="font-semibold mr-2">Queue:</span>
            <Dropdown
              title={queue?.name || 'Queue'}
              mainClass="w-44"
              buttonStyle="roundedContainer whitespace-nowrap bg-custom-bg border-border"
              listStyle="absolute zIndexTop roundedContainer w-44 bg-custom-bg"
              itemStyle="hover:bg-custom-bg hover:text-cta"
              list={queues?.map((q) => ({ name: q.name, type: q?.id }))}
              onAction={changeQueue}
            />
          </div>
        </Header>

        <div className="p-8 w-full">
          <div
            className="flex justify-between bg-custom-bg rounded-md p-3 border-border border"
            ref={scrollRef}
          >
            <h5>Total events {listData?.totalCount}</h5>
            <div className="hidden lg:block">
              <ContentFilterControl />
            </div>
            {queue?.name !== 'Quality Assurance' && (
              <div className="flex items-center">
                <div className="flex items-center gap-1 mr-2">
                  <p className="whitespace-nowrap">Select all</p>
                  <Checkbox
                    onChange={() => setInputChecked(!inputChecked)}
                    checked={inputChecked}
                  />
                </div>
                <Button
                  disabled={!selectedCS.size}
                  type="primary"
                  style="h-8 items-center"
                  onClick={() => setDecisionVisible(true)}
                >
                  <div className="w-24">
                    Action {!!selectedCS.size && <span>{selectedCS.size}</span>}
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div className="w-full grid gap-3 grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 rounded-md mt-3">
            {!isPending &&
              listData?.cases?.map((x: any) => (
                <GridCard
                  key={x?.cid}
                  contentCase={x}
                  selectedCS={selectedCS}
                  setSelectedCS={setSelectedCS}
                  queueId={queueId}
                  isNotQA={queue?.name !== 'Quality Assurance'}
                />
              ))}
          </div>
          <div className="w-full flex justify-center py-8">
            <Pagination
              currentPage={currentPage}
              pageCount={
                listData?.totalCount
                  ? Math.ceil(listData.totalCount / pageSize)
                  : 0
              }
              pageSize={pageSize}
              setPageSize={(x) => {
                setPageSize(x), setCurrentPage(0);
              }}
              gotoPage={(x) => setCurrentPage(x)}
            />
          </div>
        </div>
      </div>
      <Tray show={decisionVisible} close={() => setDecisionVisible(false)}>
        <div className="min-h-full">
          <DecisionTree {...props} />
        </div>
      </Tray>
    </>
  );
};

export default QueueBulkList;
