import { useUser } from '@context/UserProvider';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa6';
import { FaFilter } from 'react-icons/fa';
import { TbFilterOff } from 'react-icons/tb';
import { RxCross1 } from 'react-icons/rx';
import { getFullNameFromEmail } from '@utils/string';
import { useEffect, useRef, useState } from 'react';
import { useQueues } from '@context/QueuesProvider';
import { Strategy } from '@api/types';
import {
  defaultFilters,
  getInputSearchOption,
  isSingleValueFilter
} from '@components/Explore/helper';

const FilterInput = ({ k, value, searchFilters, removeFilter }: any) => {
  const val = ['author', 'parentId', 'parentComplexType'].includes(k)
    ? value
    : searchFilters?.get(k)?.get(value)?.name;

  if (!val) return null;

  return (
    <div
      key={`${k}-${value}`}
      className="roundedContainer p-1 flex bg-primary-200 text-sm text-black font-medium w-[128px] overflow-hidden mr-0.5 justify-between"
    >
      <div className="overflow-hidden text-ellipsis whitespace-nowrap pl-1">
        {getFullNameFromEmail(val)}
      </div>
      <div className="pr-1">
        <RxCross1
          size={12}
          className="hover:cursor-pointer stroke-1"
          onClick={() => removeFilter(value, k)}
        />
      </div>
    </div>
  );
};

const SearchFilter = ({
  searchDocument,
  inputQuery,
  internalState,
  setInternalState,
  strategyList
}: {
  searchDocument: () => void;
  inputQuery: string;
  internalState: any;
  setInternalState: any;
  strategyList?: Strategy[];
}) => {
  const { queues } = useQueues();
  const [showFilters, setShowFilters] = useState(false);
  const { moderators, user, domainObjects, policies } = useUser();
  const [showMainFilters, setShowMainFilters] = useState<boolean>(true);
  const [category, setCategory] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');

  const menuRef: any = useRef();
  const inputRef: any = useRef();

  useEffect(() => {
    const handler = (e: any) => {
      if (
        !menuRef?.current?.contains(e.target) &&
        !inputRef.current.contains(e.target)
      ) {
        setShowFilters(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  const searchFilters = getInputSearchOption({
    moderators,
    queues,
    policies,
    isAdmin: user?.isUserAdmin,
    domainObjects,
    strategyList
  });

  const handleSearch = (value: string) => {
    const state = new Map(internalState);
    state.set('q', value);
    setInternalState(new Map(state));
  };

  const onSelectFilter = (category: string, option: string) => {
    const state: any = new Map(internalState);
    if (isSingleValueFilter(category)) {
      state.set(category, option);
      return setInternalState(new Map(state));
    }

    if (state.has(category)) {
      state.get(category).add(option);
      return setInternalState(new Map(state));
    }
    if (!state.has(category)) {
      state.set(category, new Set([option]));
      return setInternalState(new Map(state));
    }
  };

  const removeFilter = (val: string, key: string) => {
    const state: any = new Map(internalState);
    isSingleValueFilter(key) ? state.delete(key) : state.get(key).delete(val);
    setInternalState(new Map(state));
  };

  const clearFilter = () => {
    const pid = internalState.get('pid');
    setInternalState(new Map().set('pid', pid));
  };

  const performSearch = () => {
    searchDocument();
    setShowFilters(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };

  return (
    <div className="w-full relative">
      <div className="flex gap-2">
        <button
          className="secondary rounded-md w-11 h-11 p-2"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FaFilter size="24" />
        </button>
        <div
          className="exploreInput flex flex-row h-11 w-full bg-custom-bg border-border"
          ref={inputRef}
        >
          {[...internalState.entries()].map(([key, value]) => {
            if (isSingleValueFilter(key))
              return (
                <FilterInput
                  key={`${key}-${value}`}
                  k={key}
                  value={value}
                  removeFilter={removeFilter}
                  searchFilters={searchFilters}
                />
              );

            if (defaultFilters.includes(key)) {
              return [...value]?.map((v: string) => (
                <FilterInput
                  key={`${key}-${v}`}
                  k={key}
                  value={v}
                  removeFilter={removeFilter}
                  searchFilters={searchFilters}
                />
              ));
            }
          })}
          <input
            type="text"
            className="w-full ml-1 bg-custom-bg"
            placeholder="Search content"
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={handleKeyPress}
            value={inputQuery}
          />
        </div>
      </div>
      <div
        className={`absolute max-w-max rounded-md top-[48px] w-[370px] bg-custom-bg zIndexTop border border-border shadow-lg ${
          showFilters ? 'visible' : 'invisible'
        } max-h-[430px] overflow-x-hidden`}
        ref={menuRef}
      >
        <div className="sticky top-0 bg-custom-bg">
          <div className="border-b-border border-b-[1px] text-md font-bold p-4 flex justify-between">
            <div>Filters</div>
            <button onClick={clearFilter}>
              <TbFilterOff />
            </button>
          </div>
          {!showMainFilters && (
            <div className="flex gap-3 p-4">
              <button>
                <FaChevronLeft
                  onClick={() => {
                    setShowMainFilters(true);
                    setSearchInput('');
                  }}
                />
              </button>
              <input
                type="text"
                className="w-full ml-1 border border-border bg-custom-bg p-1 rounded-md outline-none"
                placeholder=" Search here"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </div>
          )}
        </div>
        {showMainFilters ? (
          <div className="flex flex-col w-[360px]">
            {!!searchFilters &&
              [...searchFilters?.keys()]?.map((category: string) => (
                <button
                  className="flex justify-between hover:bg-highlight p-2 px-4"
                  key={category}
                  onClick={() => {
                    setShowMainFilters(false);
                    setCategory(category);
                  }}
                >
                  {category}
                  <FaChevronRight className="w-[16px] h-[16px]" />
                </button>
              ))}
          </div>
        ) : (
          <div className=" flex flex-col w-[360px] mb-4 overflow-hidden">
            {[...(searchFilters?.get(category)?.values() || [])]
              ?.filter((x: any) =>
                x.name.toLowerCase().includes(searchInput.toLowerCase())
              )
              .map((x: any) => (
                <button
                  className="flex justify-between hover:bg-highlight p-2 px-4"
                  key={x.id}
                  onClick={() => onSelectFilter(category, x.id)}
                >
                  <div className="text-nowrap truncate">{x.name}</div>
                </button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { SearchFilter };
