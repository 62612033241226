import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { Button } from '@components/shared/Buttons';
import { ChangeEvent, useState } from 'react';

const InteractiveLabel = ({
  icon,
  title,
  style,
  onClick,
  tooltip
}: {
  icon?: JSX.Element;
  title?: string;
  style?: string;
  onClick?: () => void;
  tooltip?: string;
}) => {
  const [isCopying, setIsCopying] = useState(false);
  if (!icon && !title) return null;

  const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
      return;
    }

    if (title) {
      setIsCopying(true);
      navigator.clipboard.writeText(title);
      setTimeout(() => {
        setIsCopying(false);
      }, 1000);
    }
  };

  return (
    <Tooltip text={tooltip}>
      <Button
        style="flex gap-1 items-center truncate hover:text-primary-300"
        onClick={handleClick}
      >
        {icon && <div className={`${isCopying ? 'text-cta' : ''}`}>{icon}</div>}
        <div
          className={`truncate text-left ${style}`}
        >{`${isCopying ? 'Copied' : title}`}</div>
      </Button>
    </Tooltip>
  );
};

export { InteractiveLabel };
