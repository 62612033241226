import { Policy, PolicyRule } from '@api/types';

interface PolicyExt extends Policy {
  labels: PolicyRule[];
}
const getPolicyByLabel = (label: string, policyList?: Policy[]) => {
  if (!policyList?.length) return null;

  if (!!policyList?.length && !!label) {
    const list = JSON.parse(JSON.stringify(policyList));
    const policyFilterByLabel = list
      .map((x: Policy) => {
        const rules = x.policy_rules.filter((lab) => lab?.label == label);
        if (rules.length > 0)
          return {
            ...x,
            labels: rules
          };
      })
      .filter((x: PolicyExt) => x);

    return policyFilterByLabel;
  }
};

export { getPolicyByLabel };
