import { Button } from '@components/shared/Buttons';
import { Header } from '@components/shared/Header';
import { FaChevronLeft } from 'react-icons/fa6';
import { Input } from '@components/shared/Input';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { Checkbox } from '@components/shared/Checkbox';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';

const ActionForm = ({
  isOpenComplexType,
  onSubmit,
  formInput,
  nameAlreadyExists,
  hintAlreadyExists,
  setFormInput,
  setIsOpenComplexType,
  isHintDisabled,
  actionName,
  children
}: any) => {
  const navigate = useNavigate();
  const { domainObjects } = useUser();

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id === 'complexTypes') {
      const types = new Set(formInput['complexTypes']);
      types.has(value) ? types.delete(value) : types.add(value);

      setFormInput({ ...formInput, complexTypes: [...types] });
      return;
    }
    setFormInput({ ...formInput, [id]: value });
  };

  const onChangeComplexTypeFlag = () => {
    if (isOpenComplexType) {
      setFormInput({ ...formInput, complexTypes: [] });
    }
    setIsOpenComplexType(!isOpenComplexType);
  };

  return (
    <div>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Action</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px] capitalize">
            {actionName}
          </h2>
        </div>
        <Button
          title="Submit"
          onClick={onSubmit}
          type="primary"
          disabled={
            !formInput['name'] || nameAlreadyExists || hintAlreadyExists
          }
        />
      </Header>
      <div className="p-8 flex gap-8 flex-col">
        <Button
          style="flex flex-row gap-1 items-center text-cta font-bold mb-3"
          hiddenTitle="settingActionBackBtn"
          onClick={() => navigate(-1)}
        >
          <FaChevronLeft size={14} /> Back
        </Button>
        <div className="flex flex-col gap-2">
          <Input
            htmlFor="name"
            label="Name"
            labelStyle="font-bold"
            value={formInput['name']}
            onChange={handleChange}
          />
          {nameAlreadyExists && (
            <div className="text-error">Already exists</div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Input
            htmlFor="hint"
            label="Hint"
            labelStyle="font-bold"
            value={formInput['hint']}
            onChange={handleChange}
            disabled={isHintDisabled}
          />
          {hintAlreadyExists && (
            <div className="text-error">Already exists</div>
          )}
        </div>
        {children}
        <div className="w-fit">
          <div className="flex gap-4 mb-3">
            <div className="font-bold">Complex types</div>
            <Tooltip
              textStyle="w-40"
              text="By default actions are available on all types of cases on Checkstep"
            >
              <FaInfoCircle />
            </Tooltip>
          </div>

          <Checkbox
            onChange={onChangeComplexTypeFlag}
            title="Restrict this action to specific complex types (optional)"
            checked={isOpenComplexType}
          />
          {isOpenComplexType &&
            domainObjects?.map((domainObject: any) => (
              <Checkbox
                key={domainObject.type}
                onChange={() =>
                  handleChange({
                    target: { value: domainObject.type, id: 'complexTypes' }
                  })
                }
                title={domainObject.type}
                checked={formInput['complexTypes']?.includes(domainObject.type)}
              />
            ))}
        </div>
        <div className="flex flex-col gap-4">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <textarea
            id="description"
            className="p-4 bg-custom-bg border-2 border-border rounded-md outline-none"
            form="description"
            value={formInput['description']}
            onChange={handleChange}
            maxLength={200}
          />
        </div>
      </div>
    </div>
  );
};

export { ActionForm };
