import { useState, ReactNode, useRef } from 'react';
import { Button } from '@components/shared/Buttons';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useClickOutside } from '@hooks/useClickOutside';

const DropdownButton = ({
  onClick,
  title,
  open,
  buttonStyle,
  arrow = true,
  disabled
}: {
  onClick: (val?: any) => void;
  title: string | ReactNode;
  open: boolean;
  buttonStyle?: string;
  arrow?: boolean;
  disabled?: boolean;
}) => (
  <Button
    onClick={onClick}
    style={`flex justify-between w-full gap-2 ${buttonStyle}`}
    hiddenTitle={`Dropdown open ${title}`}
    disabled={disabled}
  >
    <span className="text-ellipsis overflow-hidden">{title}</span>
    {arrow && (
      <span>
        {open && <FaChevronUp />}
        {!open && <FaChevronDown />}
      </span>
    )}
  </Button>
);

type ItemStructured = {
  name?: string;
  description?: string;
  type?: string | number | object;
};

type DropdownProps = {
  title: string | number | ReactNode;
  list: ItemStructured[] | string[] | number[];
  onAction: (item: any) => void;
  mainClass?: string;
  buttonStyle?: string;
  listStyle?: string;
  itemStyle?: string;
  keepOpen?: boolean;
  arrow?: boolean;
  disabled?: boolean;
};

const Dropdown = ({
  title,
  list,
  onAction,
  mainClass,
  buttonStyle,
  listStyle,
  itemStyle,
  keepOpen,
  arrow = true,
  disabled = false
}: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setOpen(false), open);

  if (!list || !list?.length) return null;

  const onClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const onSelect = (item: any) => {
    onAction(item);
    !keepOpen && setOpen(false);
  };

  return (
    <div ref={dropdownRef} className={`${mainClass} font-medium`}>
      <DropdownButton
        onClick={onClick}
        title={title}
        open={open}
        buttonStyle={buttonStyle}
        arrow={arrow}
        disabled={disabled}
      />
      {open && (
        <div className={`${listStyle}`}>
          {list?.map((item: any) => (
            <div key={JSON.stringify(item)}>
              <Button
                style={`link ${itemStyle} bg-custom-bg flex flex-row gap-1`}
                onClick={(e) => {
                  onSelect(item);
                  e.stopPropagation();
                }}
                hiddenTitle={`Dropdown ${title} select ${
                  item.name || item.type || item
                }`}
              >
                <div className="whitespace-nowrap">
                  {item.name || item.type || item}
                </div>
                {item.description && (
                  <div className="text-sm text-neutral-400 flex-wrap mlg:line-clamp-1">
                    - {item.description}
                  </div>
                )}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { Dropdown, DropdownButton };
