import { ChangeEvent, FormEvent } from 'react';
import { Input } from '@components/shared/Input';
import { Button } from '@components/shared/Buttons';
import { Dropdown } from '@components/shared/Dropdown';
import { useUser } from '@context/UserProvider';
import { Policy, PolicyRule } from '@api/types';

type ManageRuleType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  rule: PolicyRule;
  onChange: (e: any, key: keyof PolicyRule) => void;
  error?: string;
  title?: string;
  onClose: () => void;
  policy?: Policy;
};

const ManageRule = ({
  onSubmit,
  rule,
  onChange,
  error,
  title,
  onClose,
  policy
}: ManageRuleType) => {
  const { domainObjects } = useUser();
  return (
    <div className="max-h-[50%]">
      <h2>{title}</h2>
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="flex justify-between mb-3 gap-4 mt-4">
          <Input
            htmlFor="low"
            label="Queue content threshold %"
            type="number"
            min="0"
            max={rule.upper_threshold || 100}
            step="0.01"
            defaultValue={rule.lower_threshold || 0}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e, 'lower_threshold')
            }
            placeholder="Enter score from 0 to 1"
            required={true}
          />
          <Input
            htmlFor="high"
            label="Automatic enforcement threshold %"
            type="number"
            min={rule.lower_threshold || 0}
            max="100"
            step="0.01"
            defaultValue={rule.upper_threshold || 0}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e, 'upper_threshold')
            }
            placeholder="Enter score from 0 to 1"
            required={true}
          />
        </div>
        {domainObjects && (
          <>
            <div className="text-slate-500 text-sm mb-2">Resticted to</div>
            <Dropdown
              onAction={(item) => onChange(item, 'content_type')}
              title={rule.content_type ?? 'none'}
              list={[...domainObjects.map(({ type }) => type), 'none']}
              mainClass="w-[calc(50%-8px)] p-0 bg-custom-bg mb-3"
              buttonStyle="rounded-md border border-neutral-200 p-2 hover:bg-transparent hover:border-neutral-200"
              listStyle="absolute z-40 border bg-custom-bg border-neutral-200 rounded-sm overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta px-4"
            />
          </>
        )}
        <div className="flex">
          <div className="text-red flex">{error}</div>
          <div className="flex ml-auto gap-3">
            <Button
              onClick={onClose}
              type="secondarySmall"
              hiddenTitle="close add new Rule"
            >
              Close
            </Button>
            <Button
              onClick={onSubmit}
              type="primarySmall"
              style="h-12"
              hiddenTitle="Add New Rule"
              disabled={
                !policy || !rule?.lower_threshold || !rule?.upper_threshold
              }
            >
              Confirm
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { ManageRule };
