import { Fragment } from 'react';
import { MetadataElement } from '@api/types';
import { MetadataValue } from '@components/shared/Content/MetadataValue';

type Props = {
  metadata: any;
  showHeader?: boolean;
};

const Metadata = ({ metadata, showHeader = true }: Props) => {
  if (!metadata) return null;
  return (
    <div className="mt-2">
      {showHeader && <h5 className="my-2">Metadata</h5>}
      {metadata?.map((m: MetadataElement) => (
        <Fragment key={'meta-id-' + m.id}>
          <div className="font-bold text-md">{m.id}</div>
          <div
            key={'meta-value' + m.id}
            className="font-regular text-xs break-all"
          >
            <MetadataValue value={m.value} />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export { Metadata };
