import { Decision } from '@components/Review/DecisionTree/Decision';
import { ConfirmationRecap } from '@components/Review/DecisionTree/ConfirmationRecap';
import { PoliciesSelection } from '@components/Review/DecisionTree/PoliciesSelection';
import { CsamReport } from '@components/Review/DecisionTree/CsamReport';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { Dialog } from '@components/shared/Dialog';
import { ConfirmDecision } from '@components/Review/DecisionTree/Confirmation';
import { ConfirmSkip } from '@components/Review/DecisionTree/Confirmation/Skip';
import { ConfirmQA } from '@components/Review/DecisionTree/Confirmation/QA';
import { ConfirmDisagreement } from '@components/Review/DecisionTree/Confirmation/Disagreement';
import { hasSamePolicyViolations } from '@components/Review/utils';
import { Button } from '@components/shared/Buttons';
import { useUser } from '@context/UserProvider';
import { FaCheck } from 'react-icons/fa6';

const DecisionTree = ({
  showPolicyList,
  setShowPolicyList,
  onConfirmModalClicked,
  onSkip,
  showCsamModal,
  csamForm,
  setCsamForm,
  onConfirmCsamModalClicked,
  setShowCsamModal,
  actionSelected,
  setSelectedAction,
  selectedProperty,
  setSelectedProperty,
  payload,
  setPayload,
  isUpdatingCase,
  isUpdatingQAreview,
  contentCase,
  showModal,
  hideModal,
  modalType,
  setModalType,
  qa,
  onQAreview,
  showConfirmRecap,
  setShowConfirmRecap,
  availableActions,
  newNotes,
  setNewNotes
}: any) => {
  const { platform } = useUser();
  const onConfirmAction = () => {
    if (qa) {
      const latestDecision = contentCase.contentDecisions?.at(-1);
      const isSameDecision = actionSelected.hint === latestDecision.hint;
      if (isSameDecision && hasSamePolicyViolations(latestDecision, payload)) {
        setModalType('confirm');
        return;
      }
      setModalType('qa');
      return;
    }
    onConfirmModalClicked();
  };

  const onReset = () => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
  };

  return (
    <HotkeysProvider>
      <div className="mt-4">
        <div className="flex justify-between items-center w-full mb-8">
          <h5>Decision</h5>
          <Button
            type="secondarySmall h-9 gap-2"
            onClick={onReset}
            hiddenTitle="Reset decision selections"
          >
            <kbd className="hidden mlg:block m-0 bg-cta">Esc</kbd> Reset
          </Button>
        </div>
        <div className="flex">
          <div
            className={`${
              actionSelected ? 'bg-cta' : 'ring-1 ring-cta bg-custom-bg'
            } flex-shrink-0 w-6 h-6 rounded-lg text-white flex items-center justify-center`}
          >
            {actionSelected && <FaCheck />}
          </div>
          <p className="ml-3 text-lg font-semibold">Select a decision</p>
        </div>
        {!actionSelected && (
          <Decision
            availableActions={availableActions}
            actionSelected={actionSelected}
            setSelectedAction={setSelectedAction}
          />
        )}
        {(actionSelected?.contentDecision === 'act' ||
          actionSelected?.authorDecision === 'act' ||
          actionSelected?.authorDecision === 'hold') && (
          <PoliciesSelection
            contentCase={contentCase}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            showPolicyList={showPolicyList}
            setShowPolicyList={setShowPolicyList}
            setSelectedAction={setSelectedAction}
            setPayload={setPayload}
            showConfirmRecap={showConfirmRecap}
            setShowConfirmRecap={setShowConfirmRecap}
          />
        )}
        {showConfirmRecap && actionSelected && (
          <ConfirmationRecap
            actionSelected={actionSelected}
            setSelectedAction={setSelectedAction}
            onConfirmAction={onConfirmAction}
            setShowPolicyList={setShowPolicyList}
            isUpdatingCase={isUpdatingCase}
            payload={payload}
            setShowConfirmRecap={setShowConfirmRecap}
            setNewNotes={setNewNotes}
            newNotes={newNotes}
            qa={qa}
          />
        )}

        {!!setCsamForm && platform?.csamReportEnabled && (
          <div className="mt-2">
            <Button
              type="primary"
              title="Report CSAM"
              onClick={() => setShowCsamModal(true)}
              disabled={!actionSelected}
            />
          </div>
        )}

        <Dialog
          show={showModal}
          close={hideModal}
          dialogStyle="w-1/3 bg-custom-bg"
        >
          {modalType === 'confirm' && (
            <ConfirmDecision
              actionSelected={actionSelected}
              payload={payload}
              isLoading={isUpdatingCase}
              onSubmit={onConfirmModalClicked}
              hideModal={hideModal}
              showPolicyList={showPolicyList}
              onQAsubmit={onQAreview}
              qa={qa}
            />
          )}

          {modalType === 'skip' && (
            <ConfirmSkip
              hideModal={hideModal}
              isLoading={isUpdatingCase}
              onSubmit={onSkip}
              showPolicyList={showPolicyList}
            />
          )}

          {modalType === 'qa' && (
            <ConfirmQA
              evaluatedDecision={contentCase.contentDecisions.at(-1)}
              actionSelected={actionSelected}
              payload={payload}
              isLoading={isUpdatingQAreview}
              onSubmit={onQAreview}
              onReject={() => setModalType('disagreement')}
              hideModal={hideModal}
              showPolicyList={showPolicyList}
            />
          )}

          {modalType === 'disagreement' && (
            <ConfirmDisagreement
              onSubmit={onQAreview}
              setModalType={setModalType}
              isLoading={isUpdatingQAreview}
            />
          )}
        </Dialog>

        {!!setCsamForm && (
          <CsamReport
            showCsamModal={showCsamModal}
            hideCsamModal={() => setShowCsamModal(false)}
            actionSelected={actionSelected}
            onConfirmCsamModalClicked={onConfirmCsamModalClicked}
            csamForm={csamForm}
            setCsamForm={setCsamForm}
            content={contentCase?.contents?.at(-1)}
            isUpdatingCase={isUpdatingCase}
          />
        )}
      </div>
    </HotkeysProvider>
  );
};

export { DecisionTree };
