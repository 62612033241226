import { Button } from '@components/shared/Buttons';
import { DropdownButton } from '@components/shared/Dropdown';
import { DropdownProp, DropdownOptions } from '@typeDef/Shared';
import { useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Checkbox } from '@components/shared/Checkbox';
import { useClickOutside } from '@hooks/useClickOutside';

const Dropdown = ({
  title,
  options,
  onSelect,
  optionsSelected,
  isAdmin = true,
  align
}: DropdownProp) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setOpen(false), open);

  if (!options.length || !isAdmin) return null;

  const onSelectItem = (option: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = option.target;
    optionsSelected.has(value)
      ? optionsSelected.delete(value)
      : optionsSelected.add(value);

    onSelect(new Set(optionsSelected as Set<string>));
  };

  const clear = () => onSelect(new Set());

  return (
    <div
      className="bg-custom-bg border border-border rounded-md relative items-center flex"
      ref={dropdownRef}
    >
      <DropdownButton
        onClick={() => setOpen(!open)}
        title={title}
        open={open}
        buttonStyle="px-2"
      />
      {open && (
        <div
          className={`${align} absolute border border-border bg-custom-bg rounded-md p-2 z-[1000] top-10 w-max max-w-[500px]`}
        >
          <div className="flex justify-between items-center mb-2">
            <div className="text-md font-semibold">Filter by</div>
            <div data-testid="clearFilter">
              <Button style="link py-1" onClick={clear}>
                <IoMdClose />
              </Button>
            </div>
          </div>

          <div className="dropdown-scroll">
            {options.map((option: DropdownOptions) => (
              <Checkbox
                key={option.name}
                checked={optionsSelected.has(option.id.toString())}
                onChange={onSelectItem}
                value={option.id.toString()}
                title={option.name}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { Dropdown };
