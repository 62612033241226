type Props = {
  htmlFor: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  labelStyle?: string;
  errorMsg?: string;
  [key: string]: any;
};

const Textarea = ({
  htmlFor,
  label,
  required = false,
  placeholder,
  labelStyle = 'text-sm',
  errorMsg,
  ...rest
}: Props) => (
  <div className="flex flex-col w-full">
    <label htmlFor={htmlFor} className={`${labelStyle}`}>
      {label}
    </label>
    <textarea
      data-testid={label}
      id={htmlFor}
      className="mt-2 p-2.5 border-2 border-border rounded-md outline-none bg-custom-bg disabled:bg-bg-3"
      required={required}
      placeholder={placeholder}
      {...rest}
    />
    {errorMsg && <div className="text-error text-md mt-2">{errorMsg}</div>}
  </div>
);

export { Textarea };
